export enum NotificationType {
    Success = 'success',
    Info = 'info',
    Warning = 'warning',
    Error = 'error',
    Default = 'default'
  }
  
  export enum Position {
    TopLeft = 'top-left',
    TopRight = 'top-right',
    TopCenter = 'top-center',
    BottomLeft = 'bottom-left',
    BottomRight = 'bottom-right',
    BottomCenter = 'bottom-center'
  }