import React from 'react';
import { ButtonProps } from '../../types/button';
import clsx from 'clsx';
import { motion, HTMLMotionProps } from 'framer-motion';

type CombinedButtonProps = ButtonProps & HTMLMotionProps<'button'>;

const Button: React.FC<CombinedButtonProps> = ({
  children,
  className = '',
  variant = 'primary',
  disabled,
  ...props
}) => {
  const buttonClasses = clsx(
    '',
    {
      'bg-orange-500 border border-orange-500 text-white px-2.5 py-1.5 rounded-xl select-none':
        variant === 'primary',
      'border border-stone-700 text-stone-700 dark:text-white px-2.5 py-1.5 rounded-xl select-none':
        variant === 'secondary',
      'bg-orange-500 border border-orange-500 text-white px-2.5 py-1.5 rounded-xl shadow-orange-900 shadow-md select-none':
        variant === 'tertiary',
      'border border-orange-800  text-orange-800 px-2.5 py-1.5 rounded-xl select-none': variant === 'cancel'
    },
    className,
    disabled && 'opacity-50 cursor-not-allowed'
  );

  const MotionButton = motion.button;

  return (
    <MotionButton
      className={buttonClasses}
      disabled={disabled}
      whileHover={!disabled ? { scale: 1.04 } : undefined}
      whileTap={!disabled ? { scale: 0.96 } : undefined}
      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
      {...props}
    >
      {children}
    </MotionButton>
  );
};

export default Button;
