import React from 'react';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const {t} = useTranslation();
  return (
    <div>
      <div className="w-full">
        <div className=" ">
          <h1 className="flex justify-start items-center p-4 font-bold text-[30px] text-stone-800 dark:text-orange-600">
            {t("Dashboard")}
          </h1>
        </div>
        <div className="h-full ml-4 mt-4">
          <div className="w-full h-full text-stone-800">Dashboard page</div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
