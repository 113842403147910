import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPizzaSlice, faUtensils } from '@fortawesome/free-solid-svg-icons';
import Button from '../common/Button';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const turnBack = () => {
    navigate('/');
  };

  return (
    <div className="text-center bg-gradient-to-b from-orange-100 to-orange-200 w-screen h-screen justify-center items-center flex flex-col">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <FontAwesomeIcon icon={faPizzaSlice} className="text-6xl text-orange-500 mb-4" />
      </motion.div>
      <motion.h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        className="text-4xl font-bold text-orange-700 mb-2"
      >
        Solylock
      </motion.h1>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        className="text-xl font-semibold text-orange-600 mb-8"
      >
        {t('Delicious food, delivered to your doorstep')}
      </motion.p>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.7, duration: 0.5 }}
      >
        <p className="text-2xl font-bold text-orange-800">404</p>
        <h2 className="mt-4 text-3xl font-bold tracking-tight text-orange-900 sm:text-5xl">
          {t('Oops! Page not found')}
        </h2>
        <p className="mt-6 text-xl leading-7 text-orange-700">
          {t('Looks like this page is as elusive as our secret recipe!')}
        </p>
      </motion.div>
      <motion.div
        className="mt-10 flex items-center justify-center gap-x-6"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.9, duration: 0.5 }}
      >
        <Button className="px-6 py-3 " onClick={() => turnBack()}>
          {t('Back to our delicious menu')}
        </Button>
        <a
          href="/contact"
          className="text-lg font-semibold text-orange-700 hover:text-orange-800 transition-colors duration-300"
        >
          {t('Contact our chefs')} <FontAwesomeIcon icon={faUtensils} className="ml-2" />
        </a>
      </motion.div>
    </div>
  );
};

export default NotFoundPage;
