import React, { useEffect, useRef } from 'react';
import { X } from 'lucide-react';
import { ModalProps } from '../../types/modal';
import clsx from 'clsx';

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  description,
  children,
  className = 'max-w-md'
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.body.style.overflow = 'auto';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-slate-300 bg-opacity-50 dark:bg-black dark:bg-opacity-50 p-4">
      <div
        ref={modalRef}
        className={clsx(
          'bg-slate-200 dark:bg-stone-900 rounded-lg shadow-lg max-w-full md:h-auto max-h-full flex flex-col',
          className
        )}
      >
        <div className="flex items-center justify-between p-4 border-b border-stone-700">
          <h2 className={'font-semibold text-stone-900 dark:text-zinc-200 md:text-2xl'}>{title}</h2>
          <button
            onClick={onClose}
            className="text-stone-800 dark:text-zinc-400 hover:text-stone-500 dark:hover:text-zinc-200 transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <div className="flex-grow overflow-auto p-4">
          {description && (
            <p className="text-md text-stone-900 dark:text-zinc-200 font-semibold mb-4">
              {description}
            </p>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
