import React from 'react';
import { SvgIconProps } from '../../../types/svg';

const MoonIcon: React.FC<SvgIconProps> = ({
  color = '#FFFFFF',
  onClick
}) => (
  <svg
    onClick={onClick}
    className="shrink-0 size-8"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z"></path>
  </svg>
);

export default MoonIcon;
