import React, { useEffect, useState } from 'react';
import Form from '../../../common/Form';
import { useTranslation } from 'react-i18next';
import GoBackButton from '../../../common/GoBackButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../context/AuthContext';
import InvalidToken from './InvalidToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faLock } from '@fortawesome/free-solid-svg-icons';
import { useNotification } from '../../../../context/NotificationContext';
import { NotificationType, Position } from '../../../../types/enums/notificationEnums';
import Spinner from '../../../common/Spinner';

const ResetPasswordPage: React.FC = () => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorMessages, setErrorMessages] = useState<{
    [key: string]: string;
  }>({});
  const {
    resetPassword,
    checkTokenValidity,
    loadingStates: { resetPassword: loading }
  } = useAuth();
  const [isTokenInvalid, setIsTokenInvalid] = useState<boolean | null>(null);
  const { token } = useParams<{ token: string }>();
  const [resetEmail, setResetEmail] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      if (token) {
        const { isValid, email } = await checkTokenValidity(token);
        if (!isValid) {
          setIsTokenInvalid(true);
          if (email) {
            setResetEmail(email);
          }
        } else {
          setIsTokenInvalid(false);
        }
      }
    };

    validateToken();
  }, [token, checkTokenValidity]);

  const validateForm = (newPassword: string, confirmNewPassword: string) => {
    const errors: { [key: string]: string } = {};
    if (!newPassword) errors.newPassword = t('New Password is required');
    if (!confirmNewPassword) errors.confirmNewPassword = t('Please enter your password again');
    if (newPassword !== confirmNewPassword) errors.confirmNewPassword = t('Passwords do not match');
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validateForm(newPassword, confirmNewPassword);
    setErrorMessages(errors);
    if (Object.keys(errors).length === 0 && token) {
      try{
        const errorMessage = await resetPassword(token, newPassword, confirmNewPassword);
        if (errorMessage) {
          setErrorMessages({ ...errors, general: t(errorMessage) });
        } else {
          navigate('/login');
          showNotification(t('Password has been reset successfully.'), NotificationType.Success, Position.TopRight);
        }
      } catch (error) {
        showNotification(t("An error occured during resetting password."), NotificationType.Error, Position.TopRight);
      }
    }
  };

  const fields = [
    {
      name: 'newPassword',
      type: 'password',
      label: t('New Password'),
      placeholder: t('New Password'),
      id: 'newPassword',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)
    },
    {
      name: 'confirmNewPassword',
      type: 'password',
      label: t('Confirm New Password'),
      placeholder: t('Confirm New Password'),
      id: 'confirmNewPassword',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setConfirmNewPassword(e.target.value)
    }
  ];

  if (isTokenInvalid === null) {// buraya token'ın geçerliliği kontrol edilirken gösterilecek bir loading spinner eklensin.
    return <Spinner/>;
  }

  if (isTokenInvalid) {
    return <InvalidToken email={resetEmail} />;
  }

  return (
    <div className="flex flex-col lg:flex-row w-full h-screen justify-center items-center">
      <div className="hidden absolute top-0 left-0 w-full lg:flex justify-center items-center h-24 pb-4">
        <Link to="/">
          <p className="text-5xl font-bold bg-gradient-to-r from-amber-500 via-orange-500 to-red-500 dark:from-amber-200 dark:via-orange-400 dark:to-red-400 bg-clip-text text-transparent leading-tight select-none">
            solylock
          </p>
        </Link>
      </div>
      <div className="lg:mt-10 w-full flex items-center justify-center xl:border lg:border-slate-300 dark:lg:border-stone-700 lg:rounded-lg lg:bg-slate-100 dark:lg:bg-stone-800 lg:w-1/2 xl:w-1/3 p-6 md:p-12 lg:p-6 shadow-md">
        <div className="w-full max-w-sm flex flex-col items-center">
          <FontAwesomeIcon
            icon={faLock}
            className="flex h-16 w-16 lg:h-24 lg:w-24 mb-6 md:mb-4 text-yellow-300"
          />
          <Form
            title={t('Reset Password')}
            description={t('Enter a new password')}
            fields={fields}
            onSubmit={handleSubmit}
            errorMessages={errorMessages}
            formId="reset-password-form"
            loading={loading}
          >
            {errorMessages.general && (
              <div className="flex items-center mt-4 text-red-500">
                <FontAwesomeIcon icon={faCircleExclamation} className="h-4 w-4 mr-1 text-red-500" />
                <p className="text-sm">{errorMessages.general}</p>
              </div>
            )}
            <div className="mt-8 flex flex-col gap-y-4 w-full">
              <button
                className={`py-2 rounded-xl w-full bg-orange-500 text-zinc-200 text-lg font-bold shadow-sm shadow-orange-500 select-none ${
                  loading
                    ? 'opacity-50 cursor-not-allowed'
                    : 'active:scale-[.98] active:duration-50 hover:scale-[1.01]'
                }`}
                type="submit"
                form="reset-password-form"
                disabled={loading}
              >
                {loading ? t('Resetting password...') : t('Reset password')}
              </button>
              <GoBackButton to="/login" className="mt-4">
                {t('Go back to sign in')}
              </GoBackButton>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
