import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import UploadLogo from './UploadLogo';
import WorkShift from './WorkShift';
import LabeledInput from '../../../../common/LabeledInput';
import api from '../../../../../services/api';
import Button from '../../../../common/Button';
import WorkContact from './WorkContact';
import { useNotification } from '../../../../../context/NotificationContext';
import { SettingsFormData, WorkShiftData } from '../../../../../types/siteSettings';
import { NotificationType, Position } from '../../../../../types/enums/notificationEnums';
import clsx from 'clsx';
import Spinner from '../../../../common/Spinner';

const WebsiteSettings = () => {
  const { t } = useTranslation();
  const [workShifts, setWorkShifts] = useState<WorkShiftData[]>([]);
  const [isRestaurantOpen, setIsRestaurantOpen] = useState(true);
  const [siteSettingsId, setSiteSettingsId] = useState<string>('');
  const [includeImage, setIncludeImage] = useState<boolean>(true);
  const [formData, setFormData] = useState<SettingsFormData>({
    restaurant_name: '',
    logo_url: '',
    email: '',
    city: '',
    district: '',
    postal_code: '',
    address: '',
    phone: ''
  });
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSiteSettings = async () => {
      try {
        const response = await api.get('/admin/site-settings');
        const { data } = response.data;
        setFormData({
          ...data
        });
        setSiteSettingsId(response.data.data.id);
        setIsRestaurantOpen(response.data.data.is_open);
        setIncludeImage(response.data.data.include_image);
      } catch (error) {
        showNotification(
          'Failed to fetch site settings',
          NotificationType.Error,
          Position.TopRight
        );
      } finally {
        // setTimeout(() => {
          setLoading(false);
        // }, 300);
      }
    };

    fetchSiteSettings();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChangeWeb = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const setPhone = (phone: string) => {
    setFormData(prevState => ({
      ...prevState,
      phone
    }));
  };

  const handleLogoUpdate = (newLogoUrl: string) => {
    setFormData(prevState => ({
      ...prevState,
      logo_url: newLogoUrl
    }));
  };

  const handleWorkShiftsChange = (shifts: WorkShiftData[]) => {
    setWorkShifts(shifts);
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    const id = siteSettingsId;
    const { logo_url, ...restFormData } = formData;
    try {
      await api.put(`/admin/site-settings/${id}`, {
        ...restFormData,
        workShifts: workShifts,
        isRestaurantOpen: isRestaurantOpen,
        include_image: includeImage
      });
      showNotification('Settings saved successfully.', NotificationType.Success, Position.TopRight);
    } catch (error) {
      console.error('Failed to save site settings:', error);
      showNotification('Failed to save settings.', NotificationType.Error, Position.TopRight);
    } finally {
      setLoading(false);
    }
  };

  const toggleRestaurantStatus = () => {
    setIsRestaurantOpen(!isRestaurantOpen);
  };

  if (loading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full text-stone-900 dark:text-zinc-300">
      <div>
        <div className="flex justify-between pt-4">
          <h1 className="flex justify-start items-center font-bold text-[40px] text-stone-900 dark:text-orange-600">
            {t("WebsiteSettings")}
          </h1>
          <div className="flex items-center">
            <Button variant="tertiary" onClick={() => handleSaveChanges()}>
              {t("Save Changes")}
            </Button>
          </div>
        </div>
        <p>{t("WebsiteSettingsDesc")}</p>
        <div className="flex flex-row justify-between">
          <div
            className="mt-4 bg-transparent border-l-4 border-red-400 text-red-400 px-3 py-1"
            role="alert"
          >
            <p className="font-bold">{t("Be Warned")}</p>
            <p>{t("WarningText")}</p>
          </div>
        </div>
      </div>
      <div className="w-full flex items-center justify-start mt-8">
        <div className="flex items-center gap-2">
          <span className="text-stone-800 font-semibold dark:text-zinc-300 mr-2">
          {t("CurrentRestaurantStatus")}
          </span>
          <label className="relative flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={isRestaurantOpen}
              onChange={toggleRestaurantStatus}
              className="sr-only peer"
            />
            <div
              className={clsx(
                'w-9 h-5 peer-focus:outline-0 rounded-full peer transition-all ease-in-out duration-500',
                "after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border after:rounded-full after:h-4 after:w-4 after:transition-all",
                isRestaurantOpen ? 'peer-checked:bg-green-400' : 'bg-red-500',
                'peer-checked:after:translate-x-full peer-checked:after:border-transparent'
              )}
            ></div>
          </label>
          <span
            className={clsx('font-semibold text-lg', isRestaurantOpen ? 'text-green-400' : 'text-red-500')}
          >
            {isRestaurantOpen ? t('OPEN') : t('CLOSED')}
          </span>
        </div>
      </div>
      <div className="flex flex-col mt-8 gap-4">
        <div>
          <div className="mb-4">
            <h2 className="text-[28px] font-semibold text-stone-900 dark:text-orange-500">
              {t("Appearance")}
            </h2>
            <p>{t("AppearanceDesc")}</p>
          </div>
          <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-row gap-12 items-center h-full mt-2">
              <div className="w-1/2 h-full flex items-center">
                <LabeledInput
                  name="restaurant_name"
                  placeholder={t('Restaurant Name')}
                  id="restaurant-name"
                  width="calc(100% )"
                  value={formData.restaurant_name}
                  onChange={handleChange}
                />
              </div>
              <div className="w-2/5 h-full flex">
                <ul className="items-center w-full text-sm font-medium text-gray-900 bg-slate-300 rounded-lg sm:flex dark:bg-stone-700 dark:text-white shadow-md">
                  <li className="w-full border-b border-slate-400 sm:border-b-0 sm:border-r dark:border-stone-600 p-1.5">
                    <div className="flex items-center ps-2">
                      <input
                        id="design-without-images"
                        type="radio"
                        value=""
                        name="design-option"
                        checked={!includeImage}
                        onChange={() => setIncludeImage(false)}
                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        htmlFor="design-without-images"
                        className="w-full py-3 ms-2 text-sm font-medium text-stone-900 dark:text-gray-300"
                      >
                        {t("Design without images")}
                      </label>
                    </div>
                  </li>
                  <li className="w-full dark:border-stone-600">
                    <div className="flex items-center ps-3">
                      <input
                        id="design-with-images"
                        type="radio"
                        value=""
                        name="design-option"
                        checked={includeImage}
                        onChange={() => setIncludeImage(true)}
                        className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 focus:ring-transparent dark:focus:ring-transparent dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                      />
                      <label
                        htmlFor="design-with-images"
                        className="w-full py-3 ms-2 text-sm font-medium text-stone-900 dark:text-gray-300"
                      >
                        {t("Design with images")}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-full h-full flex items-center">
              <UploadLogo currentLogo={formData.logo_url} onLogoUpdate={handleLogoUpdate} />
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="mb-4">
            <h1 className="text-[28px] font-semibold text-stone-900 dark:text-orange-500">
              {t("Work Shift")}
            </h1>
            <p>{t("WorkShiftDesc")}</p>
          </div>
          <WorkShift onWorkShiftsChange={handleWorkShiftsChange} siteSettingsId={siteSettingsId} />
        </div>

        <div className="mb-4">
          <div className="mb-4">
            <h1 className="text-[28px] font-semibold text-stone-900 dark:text-orange-500">
            {t("Contact Information")}
            </h1>
            <p>{t("ContactInfoDesc")}</p>
          </div>
          <WorkContact
            email={formData.email}
            setPhone={setPhone}
            city={formData.city}
            district={formData.district}
            postalCode={formData.postal_code}
            address={formData.address}
            phone_number={formData.phone}
            onChangeWeb={handleChangeWeb}
          />
        </div>
      </div>
    </div>
  );
};

export default WebsiteSettings;