import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import tr from './locales/tr.json';
import fi from './locales/fi.json';

const resources = {
  fi: {
    translation: fi
  },
  en: {
    translation: en
  },
  tr: {
    translation: tr
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || 'fi', // Set Finnish as default on first visit
  fallbackLng: 'fi', // Use Finnish as the fallback language
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
