import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, Routes, Route, Navigate } from 'react-router-dom';
import UserInformation from './UserInformation';
import PasswordUpdate from './PasswordUpdate';
import AppearanceSettings from './AppearanceSettings';
import RegisteredAddresses from './Address/RegisteredAddresses';
import SelectionPanel from './SelectionPanel';
import { useAuth } from '../../../context/AuthContext';
import Spinner from '../../common/Spinner';

function Settings() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [selection, setSelection] = useState('User Informations');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Başlangıç durumu
  const { isAuthenticated, loadingStates } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (location.pathname.includes('userInformation')) {
      setSelection('User Informations');
    } else if (location.pathname.includes('passwordUpdate')) {
      setSelection('Password Update');
    } else if (location.pathname.includes('appearance')) {
      setSelection('Appearance');
    } else if (location.pathname.includes('registeredAddresses')) {
      setSelection('Registered Addresses');
    } else {
      setSelection('User Informations'); // Başlangıç değeri
    }
  }, [location.pathname]);

  const handleSelection = (selection: string) => {
    switch (selection) {
      case 'User Informations':
        navigate('/settings/userInformation');
        break;
      case 'Password Update':
        navigate('/settings/passwordUpdate');
        break;
      case 'Appearance':
        navigate('/settings/appearance');
        break;
      case 'Registered Addresses':
        navigate('/settings/registeredAddresses');
        break;
      default:
        navigate('/settings');
    }
  };

  if (loadingStates.userLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    );
  } else if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="w-full">
      <div className="flex justify-start items-start ">
        <div className="flex-col justify-start items-start pr-0 md:pr-2 lg:pr-4 hidden md:block">
          <h1 className="h-[40px] w-48 text-[40px] flex flex-col justify-start text-start text-stone-800 dark:text-white font-bold">
            {t('Settings')}
          </h1>
          <SelectionPanel selection={selection} handleSelection={handleSelection} />
        </div>

        {/* Mobil ekranlarda seçim paneli */}
        <div className="w-full pt-2 md:mt-20">
          <div className="flex h-auto text-white text-[34px] rounded-md justify-center items-center md:justify-normal md:items-normal">
            <Routes>
              {isMobile ? (
                <>
                  <Route
                    index
                    element={
                      <div className="flex flex-col justify-center items-center w-full text-[20px] sm:text-[25px] px-4 sm:px-16 ">
                        <h1 className="h-[40px] w-48 text-[40px] sm:text-[60px] flex flex-col justify-center items-center text-stone-900 dark:text-white font-bold">
                          {t('Settings')}
                        </h1>
                        <SelectionPanel selection={''} handleSelection={handleSelection} />
                      </div>
                    }
                  />
                </>
              ) : (
                <Route index element={<UserInformation />} />
              )}
              <Route path="userInformation" element={<UserInformation />} />
              <Route path="passwordUpdate" element={<PasswordUpdate />} />
              <Route path="appearance" element={<AppearanceSettings />} />
              <Route path="registeredAddresses" element={<RegisteredAddresses />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
