import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import EnglishFlag from '../../components/common/icons/flags/englishFlag';
import TurkishFlag from '../../components/common/icons/flags/turkishFlag';
import FinlandFlag from '../../components/common/icons/flags/finlandFlag';
import { faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Direction = 'row' | 'column';

const Language: React.FC<{ direction: Direction }> = ({ direction }) => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem('language'));
  const [flag, setFlag] = useState(localStorage.getItem('language') || 'fi');
  const menuRef = useRef<HTMLDivElement>(null);

  const languageHandler = (newLanguage: string) => {
    if (i18n.language !== newLanguage) {
      setLang(newLanguage);
      setFlag(newLanguage);
      i18n.changeLanguage(newLanguage);
      localStorage.setItem('language', newLanguage);
    }
    setIsOpen(false);
  };

  const getFlag = (langCode: string) => {
    switch (langCode) {
      case 'en':
        return <EnglishFlag width="24px" height="24px" />;
      case 'tr':
        return <TurkishFlag width="24px" height="24px" />;
      case 'fi':
        return <FinlandFlag width="24px" height="24px" />;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={menuRef} className="relative">
      <div
        className="border-solid border-gray-300 dark:border-stone-700 border-[1px] rounded-[5px] cursor-pointer font-bold flex justify-center items-center w-12 h-7 bg-gray-300 dark:bg-stone-800 hover:bg-gray-400 dark:hover:bg-stone-700"
        onClick={e => {
          setIsOpen(!isOpen);
        }}
      >
        {getFlag(flag)}

        {isOpen ? (
          <FontAwesomeIcon className="h-2 ml-1" icon={faChevronRight} />
        ) : (
          <FontAwesomeIcon className="w-2 ml-1" icon={faChevronUp} />
        )}
      </div>

      {isOpen && (
        <div
          className={clsx(
            direction === 'column'
              ? 'absolute top-0 left-full transform translate-x-2 -translate-y-2/3'
              : 'absolute transform left-full bottom-full -translate-y-8'
          )}
        >
          <ul className="w-24 px-1 py-1 flex flex-col items-center bg-gray-400 dark:bg-stone-700 rounded shadow-md shadow-gray-500&20 dark:shadow-stone-800 z-10 overflow-hidden">
            <li
              className={clsx(
                lang === 'en' && 'bg-stone-700 p-0.5',
                'hover:bg-stone-600 cursor-pointer mb-0.5 p-0.5 w-full'
              )}
              onClick={() => languageHandler('en')}
            >
              <div className="flex items-center">
                <EnglishFlag width="24px" height="24px" />
                <span className="ml-2 text-white">English</span>
              </div>
            </li>
            <li
              className={clsx(
                lang === 'tr' && 'bg-gray-400 dark:bg-stone-700 p-0.5',
                'hover:bg-stone-600 cursor-pointer mb-0.5 p-0.5 w-full'
              )}
              onClick={() => languageHandler('tr')}
            >
              <div className="flex items-center">
                <TurkishFlag width="24px" height="24px" />
                <span className="ml-2 text-white">Türkçe</span>
              </div>
            </li>
            <li
              className={clsx(
                lang === 'fi' && 'bg-gray-400 dark:bg-stone-700 p-0.5',
                'hover:bg-stone-600 cursor-pointer mb-0.5 p-0.5 w-full'
              )}
              onClick={() => languageHandler('fi')}
            >
              <div className="flex items-center">
                <FinlandFlag width="24px" height="24px" />
                <span className="ml-2 text-white">Suomi</span>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Language;
