import { loginPageImage, appleLogo, googleLogo, checkIcon } from '../../../../assets/images/index';
import { Link } from 'react-router-dom';
import Form from '../../../common/Form';
import Modal from '../../../common/Modal';
import CountdownButton from '../../../common/CountdownButton';
import GoBackButton from '../../../common/GoBackButton';
import { AlertCircle } from 'lucide-react';
import useLoginPageLogic from './loginPage.logic';
import { motion } from 'framer-motion';
import Button from '../../../common/Button';

const LoginPage: React.FC = () => {
  const {
    t,
    modalState,
    loginErrorMessages,
    modalErrorMessages,
    loadingStates,
    email,
    loginFields,
    forgotPasswordFields,
    rememberMe,
    countdownTime,
    handleSubmit,
    handleForgotPasswordClick,
    closeModal,
    handleForgotPasswordSubmit,
    handleRememberMeChange,
    handleGoogleSignInClick,
    handleAppleSignInClick,
    handleResendEmail,
    handleCountdownEnd
  } = useLoginPageLogic();

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="flex flex-col lg:flex-row w-full h-screen justify-center items-center"
    >
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className="w-full flex items-center justify-center lg:w-1/2 p-4 lg:p-8"
      >
        <div className="w-full lg:w-3/4 max-w-sm">
          <Form
            title={t('Sign in')}
            customTitleClass='text-[52px] mb-10'
            fields={loginFields}
            onSubmit={handleSubmit}
            errorMessages={loginErrorMessages}
            formId="login-form"
            loading={loadingStates.login}
          >
            <div className="mt-4 flex justify-between items-center">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="remember"
                  className="checkbox-custom"
                  onChange={handleRememberMeChange}
                  checked={rememberMe}
                  disabled={loadingStates.login}
                />
                <label
                  className="ml-2 font-medium text-base text-stone-900 dark:text-zinc-300 cursor-pointer select-none"
                  htmlFor="remember"
                >
                  {t('Remember me')}
                </label>
              </div>
              <button
                className="font-medium text-base text-orange-500 hover:text-orange-700 select-none"
                onClick={handleForgotPasswordClick}
                disabled={loadingStates.login}
              >
                {t('Forgot password')}
              </button>
            </div>
            {loginErrorMessages.general && (
              <div className="flex items-center mt-4 text-red-500">
                <AlertCircle className="h-4 w-4 mr-1" />
                <p className="text-sm">{loginErrorMessages.general}</p>
              </div>
            )}
            <div className="mt-6 flex flex-col gap-y-4">
              <Button
                variant="tertiary"
                className="py-2 w-full text-lg font-bold"
                type="submit"
                form="login-form"
                disabled={loadingStates.login}
              >
                {loadingStates.login ? t('Signing in...') : t('Sign in')}
              </Button>
            </div>
            <div className="flex items-center justify-center my-4">
              <div className="border-t border-slate-400 dark:border-gray-300 flex-grow"></div>
              <span className="px-4 text-gray-500 dark:text-gray-400 pointer-events-none select-none">
                {t('or')}
              </span>
              <div className="border-t border-slate-400 dark:border-gray-300 flex-grow"></div>
            </div>
          </Form>
          <div className="mt-4 flex flex-col gap-y-2">
            <button
              className="bg-white w-full flex items-center justify-center gap-4 border border-slate-300 dark:border-gray-500 rounded-xl p-2 text-gray-900 text-md font-bold shadow-sm shadow-slate-400 dark:shadow-white select-none cursor-not-allowed opacity-50"
              type="button"
              onClick={handleGoogleSignInClick}
              disabled={/*loadingStates.login*/true}
            >
              <img className="w-6 h-6" src={googleLogo} alt="google" />
              <span className="flex-1 text-center">{t('Sign in with Google')}</span>
            </button>
            <button
              className="bg-white w-full flex items-center justify-center gap-4 border border-slate-300 dark:border-gray-500 rounded-xl p-2 text-gray-900 text-md font-bold shadow-sm shadow-slate-400 dark:shadow-white select-none cursor-not-allowed opacity-50"
              type="button"
              onClick={handleAppleSignInClick}
              disabled={/*loadingStates.login*/true}
            >
              <img className="w-6 h-6" src={appleLogo} alt="apple" />
              <span className="flex-1 text-center">{t('Sign in with Apple')}</span>
            </button>
          </div>
          <div className="mt-6 flex justify-center items-center">
            <p className="font-medium text-base text-stone-900 dark:text-zinc-200">
              {t("Don't have an account?")}
            </p>
            <Link
              to="/register"
              className="text-orange-500 text-base font-medium ml-2 hover:text-orange-700 select-none"
            >
              {t('Register')}
            </Link>
          </div>
          <GoBackButton
            to="/"
            className="text-stone-900 hover:text-gray-600 dark:text-zinc-300 dark:hover:text-zinc-100 mt-6 select-none"
          >
            {t('Go back to home page')}
          </GoBackButton>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.4, duration: 0.5 }}
        className="relative w-full lg:w-1/2 h-64 lg:h-full hidden lg:flex items-center justify-center"
      >
        <img
          className="absolute w-full h-full object-cover lg:max-h-full select-none"
          src={loginPageImage}
          alt="login"
        />
      </motion.div>
      <Modal
        isOpen={modalState.isForgotPasswordModalOpen}
        onClose={closeModal}
        title={t('Forgot Password')}
        className="w-full h-auto md:max-w-md"
        description={
          modalState.isEmailSent ? (
            <>
              <img
                src={checkIcon}
                alt="email sent"
                className="w-18 h-18 mx-auto mb-2 select-none"
              />
              <p className="text-left whitespace-normal">
                {t('Password reset link has been sent successfully to ')}
                <strong>{email}</strong>
                {t(' .')}
              </p>
            </>
          ) : (
            t('We need your email address to send you the password reset link.')
          )
        }
      >
        {modalState.isEmailSent ? (
          <div>
            <p className="mt-4 text-sm text-stone-900 dark:text-zinc-200 font-light text-justify before:content-['*'] before:mr-1">
              {t(
                "If the provided email is registered, you will receive an email to reset your password. If you don't receive this email, you will need to create a new account "
              )}
              <Link className="text-blue-600 dark:text-blue-500 underline" to="/register">
                {t('here')}.
              </Link>
            </p>
            <CountdownButton
              initialTime={countdownTime}
              onCountdownEnd={handleCountdownEnd}
              onClick={handleResendEmail}
            >
              {loadingStates.forgotPassword ? t('Sending again...') : t('Send again')}
            </CountdownButton>
          </div>
        ) : (
          <Form
            fields={forgotPasswordFields}
            onSubmit={handleForgotPasswordSubmit}
            errorMessages={modalErrorMessages}
            formId="forgot-password-form"
            loading={loadingStates.forgotPassword}
          >
            {modalErrorMessages.general && (
              <div className="flex items-center mt-4 text-red-500">
                <AlertCircle className="h-4 w-4 mr-1" />
                <p className="text-sm">{modalErrorMessages.general}</p>
              </div>
            )}
            <Button
              variant='tertiary'
              type="submit"
              className="mt-6 py-2 w-full text-lg font-medium"
              form="forgot-password-form"
              disabled={loadingStates.forgotPassword}
            >
              {loadingStates.forgotPassword ? t('Sending reset link...') : t('Send reset link')}
            </Button>
          </Form>
        )}
      </Modal>
    </motion.div>
  );
};

export default LoginPage;
