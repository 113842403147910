import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { MoonIcon, SunIcon } from '../../../components/common/icons/indexIcons';

interface ThemeProps {
  shouldAnimate: boolean;
}

const Theme : React.FC<ThemeProps> = ({shouldAnimate}) => {
  const [themeLight, setThemeLight] = React.useState(localStorage.getItem('theme') === 'light');

  useEffect(() => {
    localStorage.setItem('theme', themeLight ? 'light' : 'dark');
    if (
      localStorage.theme === 'dark' ||
      (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [themeLight]);

  const themeVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: { type: 'spring', stiffness: 300, damping: 20 }
    },
    hover: { scale: 1.1 }
  };

  return (
    <motion.div
      className="mt-4 cursor-pointer"
      onClick={() => setThemeLight(!themeLight)}
      variants={themeVariants}
      initial={shouldAnimate ? 'hidden' : 'visible'}
      animate="visible"
      whileHover="hover"
    >
      {themeLight ? <MoonIcon color="#EA7C69" /> : <SunIcon color="#EA7C69" />}
    </motion.div>
  );
};

export default Theme;
