import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import TurkishFlag from '../../common/icons/flags/turkishFlag';
import FinlandFlag from '../../common/icons/flags/finlandFlag';

type Country = {
  country: string;
  code: string;
  flag: JSX.Element;
};

const countries: Country[] = [
  { country: 'Finland', code: '+358', flag: <FinlandFlag width="20px" height="20px" /> }
];
interface phoneProps {
  setPhone: (value: string) => void;
  phone_number?: string | undefined;
}

const Phone: React.FC<phoneProps> = ({ setPhone, phone_number }) => {
  const [selectedCountry, setSelectedCountry] = useState<Country>(countries[0]);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const phoneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (phone_number) {
      const country = countries.find(c => phone_number.startsWith(c.code));
      if (country) {
        setSelectedCountry(country);
        setPhoneNumber(phone_number.slice(country.code.length));
      } else {
        setPhoneNumber(phone_number);
      }
    }
  }, [phone_number]);

  const handleCountryChange = (cnt: Country) => {
    setSelectedCountry(cnt);
    setDropdownOpen(false);
    updateParentPhone(phoneNumber, cnt);
  };

  const updateParentPhone = (number: string, country: Country = selectedCountry) => {
    setPhone(number); // Only pass the actual number, without country code
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(selectedCountry.code.length);
    if (value.length <= 10) {
      setPhoneNumber(value);
      updateParentPhone(value);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (phoneRef.current && !phoneRef.current.contains(event.target as Node))
        setDropdownOpen(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full flex flex-row gap-1 text-[15px] relative">
      <div ref={phoneRef} className="w-1/3 relative">
        <div
          className="bg-slate-100 shadow-slate-300 shadow-md dark:shadow-black dark:bg-stone-700 border border-none rounded-md h-[55px] text-stone-900 dark:text-zinc-300 w-full cursor-pointer flex justify-center items-center"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <div className="w-full ml-2 flex flex-row items-center">
            <div className="text-center mr-2">{selectedCountry.flag}</div>
            <div>{selectedCountry.code}</div>
          </div>
          <div className="ml-auto mr-2">
            <FontAwesomeIcon
              className="w-2 ml-1"
              icon={dropdownOpen ? faChevronUp : faChevronDown}
            />
          </div>
        </div>

        {dropdownOpen && (
          <ul className="absolute w-full shadow-slate-300 shadow-md dark:shadow-black dark:bg-stone-700 bg-slate-100 max-h-[200px] overflow-y-scroll scrollbar-track-slate-300 dark:scrollbar-track-stone-600 scrollbar-thumb-stone-500 dark:scrollbar-thumb-orange-600 scrollbar-thin text-stone-900 dark:text-zinc-300 cursor-pointer mt-1">
            {countries.map(country => (
              <li
                key={country.country}
                className="px-2 py-1 hover:bg-slate-400 dark:hover:bg-stone-600 flex items-center"
                onClick={() => handleCountryChange(country)}
              >
                <div className="mr-2">{country.flag}</div>
                <div>{country.code}</div>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="w-2/3 ">
        <input
          type="text"
          placeholder="Phone Number"
          name="phone_number"
          id="phone_number"
          value={`${selectedCountry.code}${phoneNumber}`}
          onChange={handlePhoneNumberChange}
          className="w-full h-[55px] bg-slate-100 shadow-slate-300 shadow-md dark:shadow-black dark:bg-stone-700 text-stone-900 dark:text-zinc-300 pl-4 rounded-lg border-gray-200 dark:border-neutral-700 focus:outline-none"
          maxLength={selectedCountry.code.length + 10}
        />
      </div>
    </div>
  );
};

export default Phone;
