import React from 'react';
import { CountdownButtonProps } from '../../types/countdownButton';
import { useCountdown } from '../../context/CountdownContext';
import { useAuth } from '../../context/AuthContext';
import clsx from 'clsx';
import Button from './Button';

const CountdownButton: React.FC<CountdownButtonProps> = ({
  initialTime,
  onCountdownEnd,
  onClick,
  children,
  className = 'mt-8'
}) => {
  const { countdownTime, isCountdownActive, startCountdown } = useCountdown();
  const { loadingStates } = useAuth();

  const handleClick = () => {
    if (!isCountdownActive) {
      startCountdown(initialTime);
      onClick();
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const isButtonDisabled =
    isCountdownActive || loadingStates.resetPassword || loadingStates.forgotPassword;

  return (
    <Button
      variant='tertiary'
      className={clsx(
        'py-2 w-full text-lg font-medium',
        className
      )}
      onClick={handleClick}
      disabled={isButtonDisabled}
    >
      {children} {isCountdownActive && `(${formatTime(countdownTime)})`}
    </Button>
  );
};

export default CountdownButton;
