import React, { createContext, useState, useEffect, ReactNode } from 'react';
import api from '../services/api';
import { AuthContextType, User } from '../types/context';

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loadingStates, setLoadingStates] = useState({
    login: false,
    register: false,
    logout: false,
    forgotPassword: false,
    resetPassword: false,
    userLoading: true
  });

  const handleError = (error: any, defaultMessage: string): string => {
    const errorMessage = error.response?.data?.message || defaultMessage;
    return errorMessage;
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoadingStates(prev => ({ ...prev, userLoading: true }));
        const response = await api.get('/auth/me');
        setUser(response.data.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
        handleError(error, 'Failed to fetch user data');
      } finally {
        setLoadingStates(prev => ({ ...prev, userLoading: false }));
      }
    };

    fetchUser();
  }, []);

  const login = async (
    email: string,
    password: string,
    rememberMe: boolean
  ): Promise<string | null> => {
    try {
      setLoadingStates(prev => ({ ...prev, login: true }));
      const response = await api.post('/auth/login', { email, password, rememberMe });
      setUser(response.data.data);
      return null;
    } catch (error) {
      setUser(null);
      return handleError(error, 'An error occurred during login');
    } finally {
      setLoadingStates(prev => ({ ...prev, login: false }));
    }
  };

  const register = async (
    email: string,
    password: string,
    confirmPassword: string
  ): Promise<string | null> => {
    try {
      setLoadingStates(prev => ({ ...prev, register: true }));
      const response = await api.post('/auth/register', { email, password, confirmPassword });
      setUser(response.data.data);
      return null;
    } catch (error) {
      setUser(null);
      return handleError(error, 'An error occurred during registration');
    } finally {
      setLoadingStates(prev => ({ ...prev, register: false }));
    }
  };

  const logout = async () => {
    try {
      setLoadingStates(prev => ({ ...prev, logout: true }));
      await api.post('/auth/logout');
      setUser(null);
    } catch (error) {
      console.error('Logout failed', error);
    } finally {
      setLoadingStates(prev => ({ ...prev, logout: false }));
    }
  };

  const forgotPassword = async (email: string): Promise<string | null> => {
    try {
      setLoadingStates(prev => ({ ...prev, forgotPassword: true }));
      await api.post('/auth/forgot-password', { email });
      return null;
    } catch (error) {
      return handleError(error, 'An error occurred during sending email');
    } finally {
      setLoadingStates(prev => ({ ...prev, forgotPassword: false }));
    }
  };

  const resetPassword = async (
    token: string,
    newPassword: string,
    newPasswordAgain: string
  ): Promise<string | null> => {
    try {
      setLoadingStates(prev => ({ ...prev, resetPassword: true }));
      await api.post(`/auth/reset-password/${token}`, { newPassword, newPasswordAgain });
      return null;
    } catch (error: any) {
      if (
        error.response?.status === 401 &&
        error.response?.data?.message === 'Invalid or expired token.'
      ) {
        return 'Token is invalid or has expired';
      }
      return handleError(error, 'An error occurred during resetting password');
    } finally {
      setLoadingStates(prev => ({ ...prev, resetPassword: false }));
    }
  };

  const checkTokenValidity = async (
    token: string
  ): Promise<{ isValid: boolean; email?: string }> => {
    try {
      const response = await api.post(`/auth/verify-token/${token}`);
      return { isValid: response.data.success, email: response.data.data.email };
    } catch (error: any) {
      return {
        isValid: false,
        email: error.response?.data?.data?.email
      };
    }
  };

  const isAuthenticated = user !== null;

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        register,
        logout,
        forgotPassword,
        resetPassword,
        checkTokenValidity,
        isAuthenticated,
        loadingStates
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
