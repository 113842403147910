import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../context/AuthContext';
import { useNotification } from '../../../../context/NotificationContext';
import { NotificationType, Position } from '../../../../types/enums/notificationEnums';
import api from '../../../../services/api';
import { Address, AddressFormData } from '../../../../types/address';
import AddressList from './AddressList';
import AddAddressModal from './AddAddressModal';
import EditAddressModal from './EditAddressModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import GoBackButton from '../../../common/GoBackButton';

const RegisteredAddresses: React.FC = () => {
  const { t } = useTranslation();
  const [selectedAddress, setSelectedAddress] = useState<number | null>(null);
  const [addModal, setAddModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [placeKind, setPlaceKind] = useState<string>('Home');
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [isAdded, setIsAdded] = useState<boolean>(false);
  const { user } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const { showNotification } = useNotification();
  const [editingAddress, setEditingAddress] = useState<Address | null>(null);
  const [hasFetchedAddresses, setHasFetchedAddresses] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [formData, setFormData] = useState<AddressFormData>({
    user_id: user?.id || '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    phone_number: user?.phone_number || '',
    city: '',
    district: '',
    postal_code: '',
    address_line1: '',
    title: '',
    type: 'Home'
  });

  useEffect(() => {
    const fetchAddresses = async () => {
      setLoading(true);
      try {
        const response = await api.get('/addresses/user/' + user?.id);
        setAddresses(response.data.data);
      } catch (error) {
        console.error('Error fetching addresses:', error);
        showNotification(t('Failed to fetch addresses'), NotificationType.Error, Position.TopRight);
      } finally {
        setLoading(false);
        setHasFetchedAddresses(true);
      }
    };

    fetchAddresses();
  }, [user?.id, isAdded]);

  useEffect(() => {
    if (hasFetchedAddresses && !loading && addresses.length === 0) {
      showNotification(
        t('You have no addresses. Please add one.'),
        NotificationType.Info,
        Position.TopRight
      );
    }
  }, [addresses, loading, hasFetchedAddresses]);

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      type: placeKind
    }));
  }, [placeKind]);

  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePhoneChange = (newPhone: string) => {
    setFormData(prevState => ({
      ...prevState,
      phone_number: newPhone
    }));
  };

  const handleAddAddress = () => {
    setFormData({
      user_id: user?.id || '',
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      phone_number: user?.phone_number || '',
      city: '',
      district: '',
      postal_code: '',
      address_line1: '',
      title: '',
      type: 'Home'
    });
    setAddModal(true);
  };

  const handleEditAddress = (addressId: number) => {
    const addressToEdit = addresses.find(addr => addr.id === addressId);
    if (addressToEdit) {
      setEditingAddress(addressToEdit);
      setFormData({
        user_id: addressToEdit.user_id,
        first_name: addressToEdit.first_name || '',
        last_name: addressToEdit.last_name || '',
        phone_number: addressToEdit.phone_number || '',
        city: addressToEdit.city || '',
        district: addressToEdit.district || '',
        postal_code: addressToEdit.postal_code || '',
        address_line1: addressToEdit.address_line1,
        title: addressToEdit.title || '',
        type: addressToEdit.type
      });
      setPlaceKind(addressToEdit.type);
      setEditModal(true);
    }
  };

  const handleRemoveAddress = async (addressId: number) => {
    setIsSubmitting(true);
    try {
      await api.delete(`/addresses/remove/${addressId}`);
      showNotification(
        t('You have removed your address!'),
        NotificationType.Success,
        Position.TopRight
      );
      setIsAdded(prev => !prev);
    } catch (error) {
      showNotification(
        t('There is an error removing your address!'),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await api.post('/addresses/', formData);
      setIsAdded(prev => !prev);
      showNotification(
        t('You have added your address.'),
        NotificationType.Success,
        Position.TopRight
      );
      setAddModal(false);
    } catch (error) {
      showNotification(
        t('Problem has occurred adding your address.'),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!editingAddress) return;
    setIsSubmitting(true);
    try {
      await api.put(`/addresses/edit/${editingAddress.id}`, formData);
      setIsAdded(prev => !prev);
      showNotification(
        t('Address updated successfully.'),
        NotificationType.Success,
        Position.TopRight
      );
      setEditModal(false);
    } catch (error) {
      showNotification(
        t('Problem occurred while updating your address.'),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row w-full md:w-full h-auto md:h-auto px-4 md:px-0 ">
      <div className="w-full lg:w-3/5 bg-slate-200 dark:bg-stone-800 justify-start items-center rounded-md px-4 md:px-8">
        <div className="my-4">
          <h1 className="py-2 font-semibold text-stone-900 dark:text-orange-600">
            {t('Addresses')}
          </h1>
          <div className="pb-4 bg-slate-200 dark:bg-stone-800 h-full rounded-md block lg:hidden">
            <p className="text-stone-600 dark:text-gray-300 text-sm md:text-base">
              {t('addressDesc')}
            </p>
          </div>
        </div>

        <AddressList
          addresses={addresses}
          loading={loading}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          handleEditAddress={handleEditAddress}
          handleAddAddress={handleAddAddress}
          handleRemoveAddress={handleRemoveAddress}
        />
      </div>
      <AddAddressModal
        isOpen={addModal}
        onClose={() => setAddModal(false)}
        formData={formData}
        handleChange={handleChange}
        handlePhoneChange={handlePhoneChange}
        handleSubmit={handleSubmit}
        placeKind={placeKind}
        setPlaceKind={setPlaceKind}
        isSubmitting={isSubmitting}
      />
      <EditAddressModal
        isOpen={editModal}
        onClose={() => setEditModal(false)}
        formData={formData}
        handleChange={handleChange}
        handlePhoneChange={handlePhoneChange}
        handleEditSubmit={handleEditSubmit}
        placeKind={placeKind}
        setPlaceKind={setPlaceKind}
        isSubmitting={isSubmitting}
      />
      <div className="lg:w-2/5 ml-4 p-8 bg-slate-200 dark:bg-stone-800 h-full rounded-md hidden lg:block">
        <div className="w-full flex justify-center items-center mb-8">
          <FontAwesomeIcon
            icon={faLocationArrow}
            className="w-16 h-16 text-stone-900 dark:text-orange-600"
          />
        </div>

        <p className="text-stone-600 dark:text-gray-300 text-sm md:text-base">{t('addressDesc')}</p>
      </div>
      <GoBackButton
        to="/settings"
        className="block md:hidden mt-6 dark:text-stone-200 text-stone-800"
      >
        <span className="text-[17px] ">{t('Turn back to settings')}</span>
      </GoBackButton>
    </div>
  );
};

export default RegisteredAddresses;
