import {
  faCartArrowDown,
  faEgg,
  faLayerGroup,
  faTableColumns,
  faUsers,
  faUtensils,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectionItem {
  name: string;
  id: string;
  icon: ReactNode;
  value: string;
  isSelected: boolean;
  alt: string;
}

interface SelectionData {
  [key: string]: SelectionItem;
}

interface SelectionProps {
  selection:string
  setSelection: (value: string) => void;
}

const Selection: React.FC<SelectionProps> = ({ selection, setSelection }) => {
  const { t } = useTranslation(); // Use inside the component
  const [showed, setShowed] = useState<string>(selection);

  useEffect(() => {
    setShowed(selection);
  }, [selection]);

  const handleHighlight = (id: string) => {
    setSelection(id);
  };

  const selectionData: SelectionData = {
    Dashboard: {
      name: t('Dashboard'),
      id: 'dashboard',
      icon: <FontAwesomeIcon icon={faTableColumns} />,
      value: 'Dashboard',
      alt: t('DashboardAlt'),
      isSelected: false,
    },
    Web: {
      name: t('WebsiteSettings'),
      id: 'web',
      icon: <FontAwesomeIcon icon={faWrench} />,
      value: 'Web',
      alt: t('WebsiteSettingsAlt'),
      isSelected: false,
    },
    Orders: {
      name: t('Orders'),
      id: 'orders',
      icon: <FontAwesomeIcon icon={faCartArrowDown} />,
      value: 'Orders',
      alt: t('OrdersAlt'),
      isSelected: false,
    },
    Users: {
      name: t('Users'),
      id: 'users',
      icon: <FontAwesomeIcon icon={faUsers} />,
      value: 'Users',
      alt: t('UsersAlt'),
      isSelected: false,
    },
    Categories: {
      name: t('Categories'),
      id: 'categories',
      icon: <FontAwesomeIcon icon={faLayerGroup} />,
      value: 'Categories',
      alt: t('CategoriesAlt'),
      isSelected: false,
    },
    Products: {
      name: t('Products'),
      id: 'products',
      icon: <FontAwesomeIcon icon={faUtensils} />,
      value: 'Products',
      alt: t('ProductAlt'),
      isSelected: false,
    },
    Ingredients: {
      name: t('Ingredients'),
      id: 'ingredients',
      icon: <FontAwesomeIcon icon={faEgg} />,
      value: 'Ingredients',
      alt: t('IngredientsAlt'),
      isSelected: false,
    },
  };

  const selectionKeys = Object.keys(selectionData); // Object keys list

  return (
    <div className="w-full h-full bg-slate-200 dark:bg-stone-800 rounded-md ">
      <div className="w-full">
        <ul className="flex flex-col w-full cursor-pointer">
          {selectionKeys.map((key, index) => (
            <li
              key={selectionData[key].id}
              className={clsx(
                'h-20 px-4 py-4 flex flex-col justify-start',
                showed === selectionData[key].id &&
                  'bg-slate-300 dark:bg-orange-900 bg-opacity-50',
                index === 0 && 'rounded-t-md',
                index === selectionKeys.length - 1 && 'rounded-b-md'
              )}
              onClick={() => handleHighlight(selectionData[key].id)}
            >
              <div className="flex items-center h-full">
                <div className="flex flex-row gap-4">
                  <div
                    className={clsx(
                      showed === selectionData[key].id
                        ? 'text-orange-500'
                        : 'text-stone-900 dark:text-white',
                      'text-[17px]'
                    )}
                  >
                    {selectionData[key].icon}
                  </div>
                  <div className="flex flex-col">
                    <div
                      className={clsx(
                        showed === selectionData[key].id
                          ? 'text-orange-500'
                          : 'text-stone-900 dark:text-white',
                        'text-[17px]'
                      )}
                    >
                      {selectionData[key].name}
                    </div>
                    <div className="text-[14px] font-extralight text-stone-500 dark:text-zinc-300">
                      {selectionData[key].alt}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Selection;
