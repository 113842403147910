import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import GoBackButton from '../../common/GoBackButton';
import { Link } from 'react-router-dom';

const AppearanceSettings = () => {
  const { t } = useTranslation();

  const [fontType, setFontType] = useState('Arial');
  const [fontSize, setFontSize] = useState('12px');
  const [theme, setTheme] = useState('Dark Theme');
  const [language, setLanguage] = useState('English');
  const [formData, setFormData] = useState({
    fontType: fontType,
    fontSize: fontSize,
    theme: theme,
    language: language
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log(formData);
  };

  return (
    <div className="flex flex-col md:flex-row w-full md:w-full h-auto px-4 md:px-0 ">
      <div className="w-full lg:w-3/5 bg-slate-200 dark:bg-stone-800 rounded-md flex flex-col px-4 md:px-8">
        <div className=" my-4">
          <h1 className="py-2 font-semibold text-stone-900 dark:text-orange-600">
            {t('Appearance')}
          </h1>
          <div className="pb-4 bg-slate-200 dark:bg-stone-800 h-full rounded-md block lg:hidden">
            <p className="text-stone-600 dark:text-gray-300 text-sm md:text-base">
              Customize the look and feel of your website.
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="w-full">
          <div className="text-black">
            <div className="flex flex-col justify-start items-start">
              <div className="flex flex-col gap-2 text-sm w-full">
                <div className="flex flex-col sm:flex-row gap-2 w-full">
                  {/* Font Tipi Seçimi */}
                  <div className="flex relative w-full">
                    <select
                      id="fontType"
                      value={fontType}
                      onChange={e => setFontType(e.target.value)}
                      className="peer floating-input-custom text-stone-900 dark:text-zinc-300 bg-slate-300 dark:bg-stone-700 w-full"
                      disabled
                    >
                      <option value="" disabled hidden></option>
                      <option value="Arial">Arial</option>
                      <option value="Times New Roman">Times New Roman</option>
                      <option value="Verdana">Verdana</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Courier New">Courier New</option>
                    </select>
                    <label
                      htmlFor="fontType"
                      className="absolute left-4 top-1 text-sm text-stone-600 dark:text-gray-400"
                    >
                      {t('Font Type')}
                    </label>
                  </div>

                  {/* Font Boyutu Seçimi */}
                  <div className=" flex relative w-full">
                    <select
                      id="fontSize"
                      value={fontSize}
                      onChange={e => setFontSize(e.target.value)}
                      className="peer floating-input-custom text-stone-900 dark:text-zinc-300 bg-slate-300 dark:bg-stone-700 w-full"
                      disabled
                    >
                      <option value="" disabled hidden></option>
                      <option value="12px">12px</option>
                      <option value="14px">14px</option>
                      <option value="16px">16px</option>
                      <option value="18px">18px</option>
                      <option value="20px">20px</option>
                    </select>
                    <label
                      htmlFor="fontSize"
                      className="absolute left-4 top-1 text-sm text-stone-600 dark:text-gray-400"
                    >
                      {t('Font Size')}
                    </label>
                  </div>
                </div>

                <div className="flex flex-col sm:flex-row gap-2">
                  {/* Tema Seçimi */}
                  <div className="relative w-full">
                    <select
                      id="theme"
                      value={theme}
                      onChange={e => setTheme(e.target.value)}
                      className="peer floating-input-custom text-stone-900 dark:text-zinc-300 bg-slate-300 dark:bg-stone-700 w-full"
                      disabled
                    >
                      <option value="" disabled hidden></option>
                      <option value="light">{t('Light Theme')}</option>
                      <option value="dark">{t('Dark Theme')}</option>
                    </select>
                    <label htmlFor="theme" className="absolute left-4 top-1 text-sm text-gray-500">
                      {t('Theme')}
                    </label>
                  </div>

                  {/* Dil Tercihi Seçimi */}
                  <div className="relative w-full">
                    <select
                      id="language"
                      value={language}
                      onChange={e => setLanguage(e.target.value)}
                      className="peer floating-input-custom text-stone-900 dark:text-zinc-300 bg-slate-300 dark:bg-stone-700 w-full"
                      disabled
                    >
                      <option value="" disabled hidden>
                        {t('Select Language')}
                      </option>
                      <option value="en">English</option>
                      <option value="tr">Türkçe</option>
                      <option value="es">Suomi</option>
                    </select>
                    <label
                      htmlFor="language"
                      className="absolute left-4 top-1 text-sm text-stone-600 dark:text-gray-400"
                    >
                      {t('Language')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-3 flex flex-row gap-2 justify-center items-center md:justify-end md:items-center w-full h-14">
            <Button variant="cancel" className="w-full h-auto text-[14.5px]" disabled>
              {t('Cancel')}
            </Button>

            <Button variant="tertiary" className="w-full h-auto text-[14.5px]" disabled>
              {t('Save Changes')}
            </Button>
          </div>
        </form>
      </div>

      <div className="lg:w-2/5 ml-4 p-8 bg-slate-200 dark:bg-stone-800 h-full rounded-md hidden lg:block">
        <div className="w-full flex justify-center items-center mb-8">
          <FontAwesomeIcon icon={faEye} className="w-16 h-16 text-stone-900 dark:text-orange-600" />
        </div>

        <p className="text-stone-600 dark:text-gray-300 text-sm md:text-base">
          Customize the look and feel of your website.
        </p>
      </div>
      <GoBackButton
        to="/settings"
        className="block md:hidden mt-6 dark:text-stone-200 text-stone-800"
      >
        <span className="text-[17px] ">{t('Turn back to settings')}</span>
      </GoBackButton>
    </div>
  );
};

export default AppearanceSettings;
