import React, { createContext, useContext, useState, useEffect } from 'react';

interface ConsentContextType {
  consentGiven: boolean;
  setConsent: (value: boolean) => void;
}

interface ConsentProviderProps {
  children: React.ReactNode;
}

const ConsentContext = createContext<ConsentContextType | undefined>(undefined);

export const ConsentProvider: React.FC<ConsentProviderProps> = ({ children }) => {
  const [consentGiven, setConsentGiven] = useState<boolean>(false);

  useEffect(() => {
    const storedConsent = localStorage.getItem('cookieConsent') === 'true';
    setConsentGiven(storedConsent); // Set the state based on stored consent
  }, []);

  const setConsent = (value: boolean) => {
    localStorage.setItem('cookieConsent', value.toString()); // Store the consent in localStorage
    setConsentGiven(value); // Update the state
  };

  return (
    <ConsentContext.Provider value={{ consentGiven, setConsent }}>
      {children}
    </ConsentContext.Provider>
  );
};

export const useConsent = (): ConsentContextType => {
  const context = useContext(ConsentContext);
  if (!context) {
    throw new Error('useConsent must be used within a ConsentProvider');
  }
  return context;
};
