import React, { useEffect, useState } from 'react';
import Modal from '../../../../common/Modal';
import { Order } from '../../../../../types/orderList';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../../../../context/NotificationContext';
import Spinner from '../../../../common/Spinner';
import { NotificationType, Position } from '../../../../../types/enums/notificationEnums';
import api from '../../../../../services/api';
import clsx from 'clsx';

interface historyModalProps {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  userId: number | null;
}

const HistoryModal: React.FC<historyModalProps> = ({ modalOpen, setModalOpen, userId }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<Order[]>([]);
  const { showNotification } = useNotification();

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      if (!userId) {
        setLoading(false);
        return;
      }
      setLoading(true); // Loading state'ini true olarak ayarlayalım
      try {
        const response = await api.get(`/order/user/${userId}`);
        const fetchedOrders = response.data.data;

        const transformedOrders: Order[] = fetchedOrders.map((order: any) => ({
          orderNumber: order.id.toString(),
          datePlaced: new Date(order.order_date).toLocaleDateString(),
          totalAmount: parseFloat(order.total_price),
          status: order.status.charAt(0).toUpperCase() + order.status.slice(1)
        }));

        setOrders(transformedOrders);
      } catch (error) {
        showNotification(
          t('Error occured fetching orders. Please try again later.'),
          NotificationType.Error,
          Position.TopRight
        );
      } finally {
        setLoading(false);
      }
    };
    setOrders([]);
    fetchOrders();
  }, [userId]);

  return (
    <Modal
      isOpen={modalOpen}
      onClose={closeModal}
      title= {t("User Order List")}
      className="w-full h-auto md:max-w-2xl " /*md:min-w-[672px] max-w-2xl*/
    >
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <Spinner />
        </div>
      ) : (
        <>
          {orders.length === 0 ? (
            <p className="text-sm md:text-base lg:text-lg text-slate-400 dark:text-stone-600 mt-2 mb-4 md:mb-6 lg:mb-8">
              {t('You have no past orders.')}
            </p>
          ) : (
            orders.map(order => (
              <div
                key={order.orderNumber}
                className="mb-2 md:mb-4 lg:mb-6 border border-slate-300 dark:border-amber-600 rounded-xl overflow-hidden shadow-md shadow-slate-400 dark:shadow-amber-600/30"
              >
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center p-2 md:p-4 bg-slate-200 dark:bg-stone-700">
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 w-full mb-2 md:mb-0">
                    <div className="flex flex-col">
                      <p className="font-semibold text-xs md:text-sm text-stone-600 dark:text-zinc-400 select-none">
                        {t('Order Number')}
                      </p>
                      <p className="text-sm md:text-sm lg:text-lg font-light text-black dark:text-amber-50">
                        {order.orderNumber}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-semibold text-xs md:text-sm text-gray-700 dark:text-zinc-400 select-none">
                        {t('Date Placed')}
                      </p>
                      <p className="text-sm md:text-base lg:text-lg font-light text-black dark:text-amber-50">
                        {t(order.datePlaced)}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-semibold text-xs md:text-sm text-gray-700 dark:text-zinc-400 select-none">
                        {t('Total Price')}
                      </p>
                      <p className="text-sm md:text-base lg:text-lg font-semibold text-orange-500">
                        {t('dollar symbol')}
                        {order.totalAmount.toFixed(2)}
                        {t('other symbols')}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="font-semibold text-xs md:text-sm text-gray-700 dark:text-zinc-400 select-none">
                        {t('Status')}
                      </p>
                      <p
                        className={clsx('text-sm md:text-base lg:text-lg font-semibold', {
                          'text-red-400': order.status === 'Cancelled',
                          'text-amber-400': order.status === 'Pending',
                          'text-green-500 dark:text-green-400': order.status === 'Delivered'
                        })}
                      >
                        {t(order.status)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </>
      )}
    </Modal>
  );
};

export default HistoryModal;
