import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  LogoIcon,
  HomeIcon,
  SettingsIcon,
  HistoryIcon,
  BasketIcon,
  LoginIcon,
  LogoutIcon
} from '../../common/icons/indexIcons';
import { IconData, IconsProps } from '../../../types/sidebar';
import { useAuth } from '../../../context/AuthContext';
import { useNotification } from '../../../context/NotificationContext';
import { t } from 'i18next';
import { NotificationType, Position } from '../../../types/enums/notificationEnums';
import { useCart } from '../../../context/CartContext';

const Icons: React.FC<IconsProps> = ({
  selectedIcon,
  setSelectedIcon,
  direction,
  setMobileCart,
  mobileCart,
  isMobile
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, logout } = useAuth();
  const { showNotification } = useNotification();
  const { cartItems } = useCart();

  const pathToIconMap: { [key: string]: string } = {
    '/': 'homeIcon',
    '/order-history': 'historyIcon',
    '/basket': 'basket',
    '/settings': 'settingsIcon',
    '/login': 'loginIcon'
  };

  useEffect(() => {
    const icon = pathToIconMap[location.pathname];
    setSelectedIcon(icon);
  }, [location.pathname, setSelectedIcon]);

  const iconData: IconData[] = [
    {
      type: 'homeIcon',
      component: HomeIcon,
      route: '/'
    },
    ...(isAuthenticated
      ? [
          {
            type: 'historyIcon',
            component: HistoryIcon,
            route: '/order-history'
          }
        ]
      : []),
    {
      type: 'basket',
      component: BasketIcon,
      route: '/basket'
    },
    {
      type: isAuthenticated ? 'settingsIcon' : 'loginIcon',
      component: isAuthenticated ? SettingsIcon : LoginIcon,
      route: isAuthenticated ? '/settings' : '/login'
    },
    ...(isAuthenticated
      ? [
          {
            type: 'logoutIcon',
            component: LogoutIcon,
            route: '/'
          }
        ]
      : [])
  ];

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      showNotification(t('Logged out successfully.'), NotificationType.Success, Position.TopRight);
      handleIconClick('homeIcon', '/');
    } catch (error) {
      showNotification(t('Could not log out.'), NotificationType.Error, Position.TopRight);
    }
  }, [logout, showNotification]);

  const handleIconClick = (type: string, route: string) => {
    setSelectedIcon(type);
    if (type === 'basket') {
      setMobileCart(!mobileCart);
      if (mobileCart) {
        const icon = pathToIconMap[location.pathname] || 'homeIcon';
        setSelectedIcon(icon);
      }
    } else {
      if (mobileCart && pathToIconMap[location.pathname] === type) {
        setMobileCart(false);
      }
      if (type === 'logoutIcon') {
        handleLogout();
      } else {
        navigate(route);
      }
    }
  };

  return (
    <div className={clsx('flex', direction === 'row' ? 'flex-row ' : 'flex-col')}>
      {direction === 'column' && (
        <div className="w-20 h-20 flex justify-center items-center mb-4">
          <LogoIcon
            onClick={() => handleIconClick('homeIcon', '/')}
            className="transform transition-transform duration-300 rounded-lg cursor-pointer"
          />
        </div>
      )}

      {iconData.map(({ type, component: IconComponent, route }) => {
        const isSelected = selectedIcon === type;
        if (direction === 'column' && type === 'basket') {
          return null;
        }
        return (
          <div
            key={type}
            className={clsx(
              'rounded-xl w-16 h-16 sm:w-20 sm:h-20 flex justify-center items-center pointer-events-none relative',
              direction === 'row' ? 'gap-3' : 'gap-4 ',
              isSelected && 'bg-gray-500 dark:bg-stone-600'
            )}
          >
            {type === 'basket' && cartItems.length > 0 && (
              <div
                className={clsx(
                  'absolute text-white rounded-full w-5 h-5 flex items-center justify-center text-xs font-bold pointer-events-none',
                  isSelected ? '-top-1 -right-1 bg-red-500' : 'top-0 right-0 bg-red-500'
                )}
              >
                {cartItems.length}
              </div>
            )}
            <div
              className={clsx(
                'rounded-xl w-10 h-10 sm:w-12 sm:h-12 flex justify-center items-center',
                isSelected && 'bg-orange-500 shadow-sm shadow-orange-500'
              )}
            >
              <IconComponent
                className={clsx(
                  'transform transition-transform duration-300 rounded-lg cursor-pointer pointer-events-auto',
                  { 'hover:scale-125': !isMobile }
                )}
                onClick={() => handleIconClick(type, route)}
                color={isSelected ? '#FFFFFF' : '#EA7C69'}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Icons;