import React, { useEffect, useState } from 'react';
import { Order } from '../../../types/orderList';
import OrderList from './OrderList';
import api from '../../../services/api';
import { useAuth } from '../../../context/AuthContext';
import Spinner from '../../common/Spinner';
import { useNotification } from '../../../context/NotificationContext';
import { NotificationType, Position } from '../../../types/enums/notificationEnums';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const OrderHistoryPage: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { user, isAuthenticated, loadingStates } = useAuth();
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (loadingStates.userLoading) {
      return;
    }

    if (!loadingStates.userLoading && !isAuthenticated) {
      navigate('/');
      return;
    }

    const fetchOrders = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/order/user/${user?.id}`);
        const fetchedOrders = response.data.data;

        const transformedOrders: Order[] = fetchedOrders.map((order: any) => ({
          orderNumber: order.id.toString(),
          datePlaced: new Date(order.order_date).toLocaleDateString(),
          totalAmount: parseFloat(order.total_price),
          status: order.status.charAt(0).toUpperCase() + order.status.slice(1)
        }));

        setOrders(transformedOrders);
      } catch (error) {
        showNotification(
          t('Error occured fetching orders. Please try again later.'),
          NotificationType.Error,
          Position.TopRight
        );
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [user, isAuthenticated, showNotification, loadingStates.userLoading, t, navigate]);

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <AnimatePresence mode="wait">
        {loading ? (
          <motion.div
            key="spinner"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex w-full h-screen justify-center items-center"
          >
            <Spinner />
          </motion.div>
        ) : (
          <motion.div
            key="orderList"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ delay: 0.2 }}
          >
            <OrderList
              orders={orders}
              title="Order History"
              description="Check the status of recent orders, manage returns, and discover similar products."
              ordersLoading={loading}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default OrderHistoryPage;
