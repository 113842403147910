import React from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAddressBook,
  faEye,
  faLock,
  faMapLocationDot,
  faLanguage
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Button from '../../common/Button';
import LogoutIcon from '../../common/icons/logout';
import Language from '../../common/Language';

interface SelectionPanelProps {
  selection: string;
  handleSelection: (selection: string) => void;
}

const SelectionPanel: React.FC<SelectionPanelProps> = ({ selection, handleSelection }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <div className="flex flex-col justify-start min-w-[300px] w-full h-auto text-stone-900 dark:text-white bg-slate-200 dark:bg-stone-800 rounded-md mt-12 font-semibold">
        <ul className="cursor-pointer">
          <li
            className={clsx(
              'h-20 flex flex-row justify-start items-center pl-2',
              selection === 'User Informations' &&
                'bg-slate-300 dark:bg-orange-900 dark:bg-opacity-50 rounded-t-md'
            )}
            onClick={() => handleSelection('User Informations')}
          >
            <FontAwesomeIcon
              icon={faAddressBook}
              className={clsx(
                'w-7 mx-2 mb-5',
                selection === 'User Informations' && 'text-orange-500 dark:text-orange-400'
              )}
            />
            <ul>
              <li
                className={clsx(
                  selection === 'User Informations' && 'text-orange-500 dark:text-orange-400'
                )}
              >
                {t('User Informations')}
              </li>
              <li className="text-[14px] font-thin text-stone-500 dark:text-zinc-300">
                {t('User Informations Alt')}
              </li>
            </ul>
          </li>

          <li
            className={clsx(
              'h-20 flex flex-row justify-start items-center pl-2',
              selection === 'Password Update' &&
                'bg-slate-300 dark:bg-orange-900 dark:bg-opacity-50'
            )}
            onClick={() => handleSelection('Password Update')}
          >
            <FontAwesomeIcon
              icon={faLock}
              className={clsx(
                'w-7 mx-2 mb-5',
                selection === 'Password Update' && 'text-orange-500 dark:text-orange-400'
              )}
            />
            <ul>
              <li
                className={clsx(
                  selection === 'Password Update' && 'text-orange-500 dark:text-orange-400'
                )}
              >
                {t('Password Update')}
              </li>
              <li className="text-[14px] font-thin text-stone-500 dark:text-zinc-300">
                {t('Password Update Alt')}
              </li>
            </ul>
          </li>

          <li
            className={clsx(
              'h-20 flex flex-row justify-start items-center pl-2',
              selection === 'Appearance' && 'bg-slate-300 dark:bg-orange-900 dark:bg-opacity-50'
            )}
            onClick={() => handleSelection('Appearance')}
          >
            <FontAwesomeIcon
              icon={faEye}
              className={clsx(
                'w-7 mx-2 mb-5',
                selection === 'Appearance' && 'text-orange-500 dark:text-orange-400'
              )}
            />
            <ul>
              <li
                className={clsx(
                  selection === 'Appearance' && 'text-orange-500 dark:text-orange-400'
                )}
              >
                {t('Appearance')}
              </li>
              <li className="text-[14px] font-thin text-stone-500 dark:text-zinc-300">
                {t('Appearance Alt')}
              </li>
            </ul>
          </li>

          <li
            className={clsx(
              'h-20 flex flex-row justify-start items-center pl-2',
              selection === 'Registered Addresses' &&
                'bg-slate-300 dark:bg-orange-900 dark:bg-opacity-50 rounded-b-md'
            )}
            onClick={() => handleSelection('Registered Addresses')}
          >
            <FontAwesomeIcon
              icon={faMapLocationDot}
              className={clsx(
                'w-7 mx-2 mb-5',
                selection === 'Registered Addresses' && 'text-orange-500 dark:text-orange-400'
              )}
            />
            <ul>
              <li
                className={clsx(
                  selection === 'Registered Addresses' && 'text-orange-500 dark:text-orange-400'
                )}
              >
                {t('Addresses')}
              </li>
              <li className="text-[14px] font-thin text-stone-500 dark:text-zinc-300">
                {t('Registered Addresses Alt')}
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="mt-auto mb-3 flex flex-row gap-2 justify-end items-center w-full h-14 md:hidden">
        <div className="flex-1">
          <Language direction="row" />
        </div>
        <Button
          variant="cancel"
          className="flex flex-row w-full h-auto text-[16px] justify-center items-center"
        >
          <LogoutIcon width="20px" height="20px" color="#9a3412" />
          <p className="ml-2">{t('Logout')}</p>
        </Button>
      </div>
    </div>
  );
};

export default SelectionPanel;
