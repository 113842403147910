import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Button from '../../common/Button';
import api from '../../../services/api';
import Spinner from '../../common/Spinner';
import debounce from 'lodash/debounce';
import { ProductProps } from '../../../types/homepage';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

interface ExtendedProductProps extends ProductProps {
  onProductSelect: (productId: number) => void;
}

const ProductsWithoutImage: React.FC<ExtendedProductProps> = ({
  selectedCategory,
  searchTerm,
  categories,
  onProductSelect
}) => {
  const [pId, setPId] = useState<number>(0);
  const [productData, setProductData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [visibleProducts, setVisibleProducts] = useState<any[]>([]);
  const [itemsToShow, setItemsToShow] = useState<number>(8);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const { t } = useTranslation();

  const truncateDescription = (description: string, maxLength = 60) => {
    return description.length > maxLength
      ? `${description.substring(0, maxLength)}...`
      : description;
  };

  const getEffectiveDiscount = (product: any) => {
    const productDiscount = parseFloat(product.discount_percent);
    const category = categories.find(c => c.id.toString() === product.category_id.toString());
    const categoryDiscount = category ? parseFloat(category.discount_percent) : 0;
    return Math.max(productDiscount, categoryDiscount);
  };

  const calculateDiscountedPrice = (price: string, discountPercent: number) => {
    const basePrice = parseFloat(price);
    return (basePrice * (1 - discountPercent / 100)).toFixed(2);
  };

  // Fetch all products once
  useEffect(() => {
    const fetchAllProducts = async () => {
      try {
        const response = await api.get('/products/get-all-products/');
        setProductData(response.data.data);
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch product data:', err);
        setLoading(false);
      }
    };
    fetchAllProducts();
  }, []);

  const filteredProducts = useMemo(() => {
    return productData.filter(
      product =>
        product.active &&
        (selectedCategory === 'all' || product.category_id.toString() === selectedCategory) &&
        (product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [selectedCategory, searchTerm, productData]);

  useEffect(() => {
    setVisibleProducts(filteredProducts.slice(0, itemsToShow));
    setIsLoadingMore(false);
  }, [filteredProducts, itemsToShow]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      setIsLoadingMore(true);
      setItemsToShow(prevItemsToShow => prevItemsToShow + 4);
    }
  }, []);

  const debouncedHandleScroll = useMemo(() => debounce(handleScroll, 200), [handleScroll]);

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
      debouncedHandleScroll.cancel();
    };
  }, [debouncedHandleScroll]);

  if (loading) {
    return (
      <div className="mt-20">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
        className="mt-8 overflow-x-hidden overflow-y-auto w-screen md:w-full"
      >
        <motion.h3
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.7 }}
          className="text-stone-800 dark:text-white text-2xl font-semibold"
        >
          Choose your dishes
        </motion.h3>
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 md:gap-6 w-[94%] md:w-full px-3 py-6">
          <AnimatePresence>
            {visibleProducts.map(
              (product, index) =>
                product.active && (
                  <motion.div
                    key={product.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3, delay: index * 0.05 }}
                    className="px-3.5 py-4 bg-white dark:bg-stone-700 rounded-3xl shadow-lg border border-gray-100 dark:border-stone-700 shadow-gray-500/30 flex flex-col justify-between items-center"
                  >
                    {getEffectiveDiscount(product) > 0 && (
                      <div className="absolute top-3 right-2 bg-green-500 text-white text-sm font-bold px-2 py-1 rounded-full">
                        {getEffectiveDiscount(product)}%
                      </div>
                    )}

                    <h3 className="text-black dark:text-white font-bold text-2xl mt-2 break-words">
                      {product.product_name}
                    </h3>
                    <p className="text-stone-400 text-md font-thin text-center">
                      {truncateDescription(product.description, 40)}
                    </p>
                    <div className="flex flex-col justify-between items-center mt-2">
                      <div className="flex items-center gap-3">
                        {getEffectiveDiscount(product) > 0 ? (
                          <>
                            <p className="text-gray-500 dark:text-gray-400 line-through text-lg text-center">
                              €{parseFloat(product.price).toFixed(2)}
                            </p>
                            <p className="text-black dark:text-white font-bold text-xl">
                              €
                              {calculateDiscountedPrice(
                                product.price,
                                getEffectiveDiscount(product)
                              )}
                            </p>
                          </>
                        ) : (
                          <p className="text-black dark:text-white font-bold text-xl">
                            €{parseFloat(product.price).toFixed(2)}
                          </p>
                        )}
                      </div>
                      <Button
                        variant="tertiary"
                        onClick={() => onProductSelect(product.id)}
                        className="w-full mt-2"
                      >
                        {t('Add to cart')}
                      </Button>
                    </div>
                  </motion.div>
                )
            )}
          </AnimatePresence>
        </div>
        {isLoadingMore && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="flex justify-center my-4"
          >
            <Spinner />
          </motion.div>
        )}
      </motion.div>
    </>
  );
};

export default ProductsWithoutImage;
