import React from 'react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { RenderZoomProps, zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { SelectionMode, selectionModePlugin } from '@react-pdf-viewer/selection-mode';

interface PDFFileProps {
  url: string;
}

const PDFViewer: React.FC<PDFFileProps> = ({ url }) => {
  const zoomPluginInstance = zoomPlugin({ enableShortcuts: true });
  const { Zoom } = zoomPluginInstance;
  const selectionModePluginInstance = selectionModePlugin();
  const { SwitchSelectionMode } = selectionModePluginInstance;

  const CustomZoom: React.FC<RenderZoomProps> = ({ scale, onZoom }) => {
    const zoomLevels = [
      { label: 'Page width', value: SpecialZoomLevel.PageWidth },
      { label: 'Actual size', value: 1 },
      { label: '50%', value: 0.5 },
      { label: '75%', value: 0.75 },
      { label: '125%', value: 1.25 },
      { label: '150%', value: 1.5 },
      { label: '200%', value: 2 },
      { label: '300%', value: 3 }
    ];

    return (
      <select
        value={scale}
        onChange={e => {
          const value = e.target.value;
          const zoomLevel = value === SpecialZoomLevel.PageWidth ? value : parseFloat(value);
          onZoom(zoomLevel);
        }}
        className="border border-gray-300 rounded px-2 py-1"
      >
        {zoomLevels.map(level => (
          <option key={level.label} value={level.value}>
            {level.label}
          </option>
        ))}
      </select>
    );
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    renderToolbar: Toolbar => (
      <div className="flex flex-wrap justify-between items-center px-2 py-0.5 w-full border-b border-gray-300">
        <div className="flex items-center flex-1 space-x-2 mt-2">
          <div className="hidden md:flex">
            <SwitchSelectionMode mode={SelectionMode.Hand} />
            <SwitchSelectionMode mode={SelectionMode.Text} />
          </div>
        </div>
        <div className="flex items-center justify-center flex-1">
          <Toolbar>
            {({ NumberOfPages, CurrentPageInput }) => (
              <div className="flex items-center space-x-4">
                <div className="hidden md:flex items-center">
                  <div className="flex items-center justify-center w-12">
                    <CurrentPageInput />
                  </div>
                  <span className="mx-1">/</span>
                  <NumberOfPages />
                </div>
                <div className="flex space-x-1 items-center">
                  <Zoom>{(props: RenderZoomProps) => <CustomZoom {...props} />}</Zoom>
                </div>
              </div>
            )}
          </Toolbar>
        </div>
        <div className="flex justify-end flex-1 space-x-2 mt-2">
          <Toolbar>
            {({ Download, Print }) => (
              <>
                <div className='hidden md:flex'>
                  <Print />
                </div>
                <div>
                  <Download />
                </div>
              </>
            )}
          </Toolbar>
        </div>
      </div>
    )
  });

  return (
    <div className="h-full w-full overflow-auto">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Viewer
          fileUrl={url}
          plugins={[defaultLayoutPluginInstance, zoomPluginInstance, selectionModePluginInstance]}
          defaultScale={SpecialZoomLevel.PageWidth}
        />
      </Worker>
    </div>
  );
};

export default PDFViewer;
