import React, { useEffect, useState } from 'react';
import Selection from './Selection';
import Dashboard from './Menus/Dashboard';
import Listusers from './Menus/Users/Listusers';
import ListProducts from './Menus/ListProducts';
import Categories from './Menus/Categories';
import WebsiteSettings from './Menus/WebsiteSettings/WebsiteSettings';
import Ingredients from './Menus/Ingredients';
import Orders from './Menus/Orders/Orders';
import { useAuth } from '../../../context/AuthContext';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../../common/Spinner';
import { useTranslation } from 'react-i18next';

const Admin = () => {
  const { t } = useTranslation();
  const [selection, setSelection] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loadingStates } = useAuth();

  useEffect(() => {
    if (location.pathname.includes('dashboard')) {
      setSelection('dashboard');
    } else if (location.pathname.includes('users')) {
      setSelection('users');
    } else if (location.pathname.includes('products')) {
      setSelection('products');
    } else if (location.pathname.includes('categories')) {
      setSelection('categories');
    } else if (location.pathname.includes('web')) {
      setSelection('web');
    } else if (location.pathname.includes('ingredients')) {
      setSelection('ingredients');
    } else if (location.pathname.includes('orders')) {
      setSelection('orders');
    } else {
      setSelection('dashboard'); // Başlangıç değeri
    }
  }, [location.pathname]);

  const renderComponent = (sel: string) => {
    if (sel === 'dashboard') {
      navigate('/admin/dashboard');
    } else if (sel === 'users') {
      navigate('/admin/users');
    } else if (sel === 'products') {
      navigate('/admin/products');
    } else if (sel === 'categories') {
      navigate('/admin/categories');
    } else if (sel === 'web') {
      navigate('/admin/web');
    } else if (sel === 'ingredients') {
      navigate('/admin/ingredients');
    } else if (sel === 'orders') {
      navigate('/admin/orders');
    } else {
      navigate('/admin/dashboard');
    }
  };

  if (loadingStates.userLoading) {
    console.log('User is still loading');
    return <Spinner />;
  }
  if (!user) {
    console.log('No user found, redirecting');
    return <Navigate to="/" replace />;
  }

  if (user.role !== 'staff') {
    console.log('User is not staff, redirecting');
    return <Navigate to="/" replace />;
  }
  return (
    <div className="flex flex-row text-zinc-200 w-full">
      <div className="flex flex-col justify-start items-center min-w-[300px] ">
        <div className="flex-col justify-start items-start w-full ">
          <h1 className="w-full text-[40px] font-bold mb-5 text-stone-800 dark:text-white">
            {t('Admin')}
          </h1>
          <div>
            <Selection selection={selection} setSelection={renderComponent} />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full mt-20 ml-4 px-8 rounded-md text-zinc-200 bg-slate-200 dark:bg-stone-800">
        <div className="w-full ">
          <Routes>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="users" element={<Listusers />} />
            <Route path="products" element={<ListProducts />} />
            <Route path="categories" element={<Categories />} />
            <Route path="web" element={<WebsiteSettings />} />
            <Route path="ingredients" element={<Ingredients />} />
            <Route path="orders" element={<Orders />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Admin;
