import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useCallback
} from 'react';
import { CartItem } from '../types/cart';
import api from '../services/api';
import { useAuth } from './AuthContext';
import { useNotification } from './NotificationContext';
import { NotificationType, Position } from '../types/enums/notificationEnums';
import { AddToCartRequest } from '../types/cartRequest';
import { CartContextType } from '../types/cartContext';
import { t } from 'i18next';

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [initialCartItems, setInitialCartItems] = useState<CartItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { user, isAuthenticated } = useAuth();
  const { showNotification } = useNotification();
  const cartId = cartItems?.[0]?.cart_id;

  const fetchCartItems = useCallback(async (): Promise<void> => {
    if (!user?.id) return;
    setLoading(true);
    try {
      const response = await api.get(`/cart/user/${user.id}`);
      if (response.data.success && response.data.data?.cartItems) {
        setCartItems(response.data.data.cartItems);
        setInitialCartItems(response.data.data.cartItems);
      } else {
        setCartItems([]);
        setInitialCartItems([]);
      }
    } catch (error) {
      showNotification(
        t('Failed to fetch cart items. Please try again.'),
        NotificationType.Error,
        Position.TopRight
      );
      setCartItems([]);
      setInitialCartItems([]);
    } finally {
      setLoading(false);
    }
  }, [user?.id, showNotification]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchCartItems();
    } else {
      setCartItems([]);
      setInitialCartItems([]);
    }
  }, [fetchCartItems, isAuthenticated]);

  const addToCart = async (item: AddToCartRequest): Promise<void> => {
    setLoading(true);
    try {
      const response = await api.post('/cart/add-to-cart', item);
      if (response.data.success) {
        await fetchCartItems();
        showNotification(
          t('Item added to cart successfully.'),
          NotificationType.Success,
          Position.TopRight
        );
      }
    } catch (error) {
      showNotification(
        t('Failed to add item to cart. Please try again later.'),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setLoading(false);
    }
  };

  const removeItemFromCart = async (itemId: number): Promise<void> => {
    setLoading(true);
    try {
      const response = await api.delete(`/cart/delete-item/${itemId}`);
      if (response.data.success) {
        setCartItems(prevItems => prevItems.filter(item => item.id !== itemId));
        showNotification(
          t('Item removed successfully.'),
          NotificationType.Success,
          Position.TopRight
        );
      }
    } catch (error) {
      showNotification(
        t('Failed to remove item. Please try again later.'),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setLoading(false);
    }
  };

  const clearCart = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await api.delete(`/cart/clear/${cartItems[0].cart_id}`);
      if (response.data.success) {
        setCartItems([]);
        setInitialCartItems([]);
        showNotification(
          t('Cart cleared successfully.'),
          NotificationType.Success,
          Position.TopRight
        );
      }
    } catch (error) {
      showNotification(
        t('Failed to clear cart. Please try again later.'),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setLoading(false);
    }
  };

  const confirmCart = async (): Promise<boolean> => {
    const hasCartChanged = JSON.stringify(cartItems) !== JSON.stringify(initialCartItems);
    if (!hasCartChanged) {
      return true; // to prevent unnecessary API call
    }

    setLoading(true);
    try {
      const cartItemsToUpdate = cartItems.map(({ id, quantity }) => ({ id, quantity }));
      const response = await api.put('/cart/confirm', {
        cartItems: cartItemsToUpdate,
        cartId: cartId
      });

      if (response.data.success) {
        setInitialCartItems(cartItems);
        return true;
      } else {
        showNotification(
          t('Failed to confirm cart. Please try again later.'),
          NotificationType.Error,
          Position.TopRight
        );
        return false;
      }
    } catch (error) {
      showNotification(
        t('Failed to confirm cart. Please try again later.'),
        NotificationType.Error,
        Position.TopRight
      );
      return false;
    } finally {
      setLoading(false);
    }
  };

  const calculateDiscountedPrice = (
    price: number,
    productDiscount: number,
    categoryDiscount: number
  ): number => {
    const effectiveDiscount = Math.max(productDiscount, categoryDiscount);
    return price * (1 - effectiveDiscount / 100);
  };

  const calculateSubTotal = () => {
    const subTotal = cartItems.reduce(
      (total, item) =>
        total +
        Number(
          calculateDiscountedPrice(item.price, item.product_discount, item.category_discount)
        ) *
          item.quantity,
      0
    );
    return parseFloat(subTotal.toFixed(2));
  };

  const resetCart = useCallback(() => {
    setCartItems([]);
    setInitialCartItems([]);
  }, []);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        cartId,
        loading,
        setLoading,
        setCartItems,
        addToCart,
        removeItemFromCart,
        clearCart,
        confirmCart,
        fetchCartItems,
        calculateDiscountedPrice,
        calculateSubTotal,
        resetCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
