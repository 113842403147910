import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LabeledInput from '../../common/LabeledInput';
import 'react-phone-input-2/lib/high-res.css';
import Button from '../../common/Button';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Phone from './Phone';
import api from '../../../services/api';
import { useAuth } from '../../../context/AuthContext';
import Spinner from '../../common/Spinner';
import { NotificationType, Position } from '../../../types/enums/notificationEnums';
import { useNotification } from '../../../context/NotificationContext';
import GoBackButton from '../../common/GoBackButton';

const UserInformation: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const uid = user?.id;
  const [phone, setPhone] = useState('');
  const [formData, setFormData] = useState({
    id: user?.id || '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    phone_number: user?.phone_number || phone,
    email: user?.email || ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const response = await api.get('/user/' + uid);
        setFormData(response.data.data);
        setPhone(response.data.data.phone_number || '');
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
  }, [uid]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    const { first_name, last_name, phone_number, email } = formData;
    const userData = { first_name, last_name, phone_number, email };
    try {
      await api.put(`/user/update-user/${formData.id}`, userData);
      showNotification(
        t('User information updated successfully'),
        NotificationType.Success,
        Position.TopRight
      );
    } catch (error) {
      showNotification(
        t('User information updated failed'),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFormData({
      id: user?.id || '',
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      phone_number: user?.phone_number || phone,
      email: user?.email || ''
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      phone_number: phone
    }));
  }, [phone]);

  return (
    <div className="flex flex-col md:flex-row w-full md:w-full h-auto md:h-auto px-4 md:px-0 ">
      <div className="w-full lg:w-3/5 bg-slate-200 dark:bg-stone-800 justify-start items-center rounded-md px-4 md:px-8">
        <div className="my-4">
          <h1 className="py-2 font-semibold text-stone-900 dark:text-orange-600">
            {t('User Informations')}
          </h1>
          <div className="pb-4 bg-slate-200 dark:bg-stone-800 h-full rounded-md block lg:hidden">
            <p className="text-stone-600 dark:text-gray-300 text-sm md:text-base">
              {t('userInfoDesc')}
            </p>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <form
            onSubmit={handleSubmit}
            className="flex flex-col w-full text-zinc-300 dark:text-black gap-2"
            id="user-information-form"
          >
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-2">
                <LabeledInput
                  name="first_name"
                  placeholder={t('Name')}
                  id="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  width="calc(60% - 0.5rem)"
                />
                <LabeledInput
                  name="last_name"
                  placeholder={t('Surname')}
                  id="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  width="calc(60% - 0.5rem)"
                />
              </div>
              <LabeledInput
                name="email"
                placeholder={t('E-mail')}
                id="email"
                width="100%" // Tam genişlik
                value={formData.email}
                onChange={handleChange}
              />
              <Phone setPhone={setPhone} phone_number={formData.phone_number} />
            </div>

            <div className="mt-auto mb-3 flex flex-row gap-2 justify-end items-center w-full h-14">
              <Button
                variant="cancel"
                className="w-full h-auto text-[14.5px]"
                onClick={e => handleCancel(e)}
                disabled={isSubmitting}
              >
                {t('Cancel')}
              </Button>
              <Button
                variant="tertiary"
                className="w-full h-auto text-[14.5px]"
                disabled={isSubmitting}
              >
                {isSubmitting ? <Spinner size="sm" /> : t('Save Changes')}
              </Button>
            </div>
          </form>
        )}
      </div>
      <div className="lg:w-2/5 ml-4 p-8 bg-slate-200 dark:bg-stone-800 h-full rounded-md hidden lg:block">
        <div className="w-full flex justify-center items-center mb-8">
          <FontAwesomeIcon
            icon={faAddressBook}
            className="w-16 h-16 text-stone-900 dark:text-orange-600"
          />
        </div>

        <p className="text-stone-600 dark:text-gray-300 text-sm md:text-base">
          {t('userInfoDesc')}
        </p>
      </div>
      <GoBackButton
        to="/settings"
        className="block md:hidden mt-6 dark:text-stone-200 text-stone-800"
      >
        <span className="text-[17px] ">{t('Turn back to settings')}</span>
      </GoBackButton>
    </div>
  );
};

export default UserInformation;
