import React from 'react';
import { SvgIconProps } from '../../../types/svg';

const HomeIcon: React.FC<SvgIconProps> = ({
  color = '#FFFFFF',
  height = '24px',
  width = '24px',
  onClick,
  className
}) => (
  <svg
    className={className}
    height={height}
    width={width}
    onClick={onClick}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.73024 2.78835C11.0002 1.77935 12.7802 1.73935 14.0892 2.66835L14.2502 2.78835L20.3392 7.65935C21.0092 8.17835 21.4202 8.94936 21.4902 9.78835L21.5002 9.98935V18.0984C21.5002 20.1884 19.8492 21.8884 17.7802 21.9984H15.7902C14.8392 21.9794 14.0702 21.2394 14.0002 20.3094L13.9902 20.1684V17.3094C13.9902 16.9984 13.7592 16.7394 13.4502 16.6884L13.3602 16.6784H10.6892C10.3702 16.6884 10.1102 16.9184 10.0702 17.2184L10.0602 17.3094V20.1594C10.0602 20.2184 10.0492 20.2884 10.0402 20.3384L10.0302 20.3594L10.0192 20.4284C9.90024 21.2794 9.20024 21.9284 8.33024 21.9894L8.20024 21.9984H6.41024C4.32024 21.9984 2.61024 20.3594 2.50024 18.2984V9.98935C2.50924 9.13835 2.88024 8.34835 3.50024 7.79835L9.73024 2.78835ZM13.3802 3.87835C12.6202 3.26835 11.5402 3.23935 10.7402 3.76835L10.5892 3.87835L4.50924 8.77936C4.16024 9.03835 3.95024 9.42836 3.90024 9.83836L3.88924 9.99835V18.0984C3.88924 19.4284 4.92924 20.5184 6.25024 20.5984H8.20024C8.42024 20.5984 8.61024 20.4494 8.63924 20.2394L8.66024 20.0594L8.67024 20.0084V17.3094C8.67024 16.2394 9.49024 15.3694 10.5402 15.2884H13.3602C14.4292 15.2884 15.2992 16.1094 15.3802 17.1594V20.1684C15.3802 20.3784 15.5302 20.5594 15.7302 20.5984H17.5892C18.9292 20.5984 20.0192 19.5694 20.0992 18.2584L20.1102 18.0984V9.99835C20.0992 9.56936 19.9202 9.16835 19.6102 8.86935L19.4802 8.75835L13.3802 3.87835Z"
      fill={color}
    />
  </svg>
);

export default HomeIcon;
