import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import './i18n';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/tailwind.css';
import App from './App';
import { AuthProvider } from './context/AuthContext';
import { CountdownProvider } from './context/CountdownContext';
import { NotificationProvider } from './context/NotificationContext';
import { CartProvider } from './context/CartContext';
import { WebSettingsProvider } from './context/WebSettingsContext';
import { ConsentProvider } from './context/CookieConsentContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ConsentProvider>
      <AuthProvider>
        <CountdownProvider>
          <NotificationProvider>
            <CartProvider>
              <App />
            </CartProvider>
          </NotificationProvider>
        </CountdownProvider>
      </AuthProvider>
    </ConsentProvider>
  </React.StrictMode>
);
