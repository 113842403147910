import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderDetailsProps } from '../../../types/payment';
import { useCart } from '../../../context/CartContext';
import { useWebSettings } from '../../../context/WebSettingsContext';

const OrderDetails: React.FC<OrderDetailsProps> = ({ orderDetails, orderNote, setOrderNote }) => {
  const { t } = useTranslation();
  const { calculateDiscountedPrice } = useCart();
  const { webData } = useWebSettings();
  const isImage = webData?.include_image === 1;

  return (
    <div className="bg-slate-200 dark:bg-stone-800 border border-slate-300 dark:border-stone-700 rounded-xl p-6 -z-10">
      <h2 className="text-xl md:text-2xl font-semibold text-stone-900 dark:text-orange-400 mb-4">
        {t('Order Details')}
      </h2>
      {!orderDetails ? (
        <div>No order details available.</div>
      ) : (
        <>
          {orderDetails.map((item, index) => (
            <div
              key={index}
              className="flex flex-row md:flex-row space-x-4 md:space-x-6 items-stretch border-t border-slate-400 dark:border-stone-600 pt-4 md:pt-6 pb-4 md:pb-6 last:border-b-0 last:pb-0 z-10"
            >
              {isImage && (
                <img
                  src={item.image_url}
                  alt={item.product_name}
                  className="w-24 h-24 md:w-32 md:h-28 object-contain rounded-lg select-none"
                />
              )}

              <div className="flex-grow flex flex-col justify-between">
                <div className="flex flex-col md:flex-row justify-between items-start mb-2">
                  <h3 className="font-bold text-base md:text-xl text-gray-700 dark:text-amber-50 mb-1 md:mb-0">
                    {item.product_name}
                  </h3>
                  <div className="flex items-center space-x-2">
                    {Math.max(item.product_discount, item.category_discount) > 0 ? (
                      <>
                        <span className="text-gray-500 dark:text-gray-400 line-through text-sm">
                          {Number(item.price).toFixed(2)}€
                        </span>
                        <span className="font-bold text-base md:text-xl text-orange-500">
                          {calculateDiscountedPrice(
                            item.price,
                            item.product_discount,
                            item.category_discount
                          ).toFixed(2)}
                          €
                        </span>
                      </>
                    ) : (
                      <span className="font-bold text-base md:text-xl text-orange-500">
                        {Number(item.price).toFixed(2)}€
                      </span>
                    )}
                  </div>
                </div>
                <p className="hidden md:block text-xs lg:text-sm text-justify mb-4 text-gray-700 dark:text-zinc-300">
                  {item.description}
                </p>
                <div className="flex flex-wrap items-center justify-between">
                  <div className="flex flex-col md:flex-row md:items-center md:space-x-4">
                    <div className="flex items-center">
                      <span className="text-xs md:text-base font-semibold text-slate-700 dark:text-zinc-400 mr-2 select-none">
                        {t('Quantity')}:
                      </span>
                      <span className="text-sm md:text-base text-slate-700 dark:text-amber-50">
                        {item.quantity}
                      </span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-xs md:text-base font-semibold text-slate-700 dark:text-zinc-400 mr-2 select-none">
                        {t('Size')}:
                      </span>
                      <span className="text-sm md:text-base text-slate-700 dark:text-amber-50">
                        {item.size_name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="mt-8">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-slate-700 dark:text-zinc-200"
            >
              {t('Add a note to your order:')}
            </label>
            <textarea
              id="message"
              rows={4}
              className="resize-none block p-2.5 w-full text-sm text-stone-900 bg-slate-100 dark:text-zinc-200 dark:bg-stone-700 rounded-lg border border-slate-400 dark:border-stone-600 focus:ring-orange-500 focus:border-orange-500 placeholder-slate-400 dark:placeholder-stone-500"
              placeholder={t('Write your note here...')}
              value={orderNote}
              onChange={e => setOrderNote(e.target.value)}
            ></textarea>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderDetails;
