import React, { createContext, useContext, ReactNode, useRef } from 'react';
import { ToastContainer, toast, Flip, Bounce, Slide, Zoom } from 'react-toastify';
import { NotificationType, Position } from '../types/enums/notificationEnums';
import { NotificationContextProps } from '../types/notificationTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const activeNotificationsRef = useRef<Set<string>>(new Set());
  const { t } = useTranslation();
  const showNotification = (
    message: string,
    type: NotificationType = NotificationType.Default,
    position: Position = Position.TopRight,
    title?: string
  ) => {
    const defaultTitles = {
      [NotificationType.Success]: t('Success!'),
      [NotificationType.Error]: t('Error!'),
      [NotificationType.Info]: t('Info'),
      [NotificationType.Warning]: t('Warning!'),
      [NotificationType.Default]: t('Notification')
    };

    const notificationTitle = title || defaultTitles[type];

    const notificationId = `${type}-${notificationTitle}-${message}`;

    if (activeNotificationsRef.current.has(notificationId)) {
      return;
    }

    const options = {
      position: position,
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      transition: Zoom,
      onOpen: () => {
        activeNotificationsRef.current.add(notificationId);
      },
      onClose: () => {
        activeNotificationsRef.current.delete(notificationId);
      },
      icon: ({ type }: { type: string }) => {
        switch (type) {
          case 'success':
            return (
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-emerald-400 dark:text-white/80 w-6 h-6"
              />
            );
          case 'error':
            return (
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="text-red-400 dark:text-white/80 w-6 h-6"
              />
            );
          case 'info':
            return (
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="text-blue-400 dark:text-white/80 w-6 h-6"
              />
            );
          case 'warning':
            return (
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className="text-yellow-400 dark:text-white/80 w-6 h-6"
              />
            );
          default:
            return null;
        }
      }
    };

    const content = (
      <div>
        {notificationTitle && <div className="font-semibold mb-1">{notificationTitle}</div>}
        <div>{message}</div>
      </div>
    );

    switch (type) {
      case NotificationType.Success:
        toast.success(content, options);
        break;
      case NotificationType.Info:
        toast.info(content, options);
        break;
      case NotificationType.Warning:
        toast.warning(content, options);
        break;
      case NotificationType.Error:
        toast.error(content, options);
        break;
      default:
        toast(content, options);
        break;
    }
  };

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <ToastContainer limit={2} />
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};
