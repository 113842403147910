import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const LoginLayout: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const { isAuthenticated, loadingStates } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  useEffect(() => {
    if (loadingStates.userLoading) {
      return;
    }

    if (
      !isAuthenticated &&
      pathname !== '/login' &&
      pathname !== '/register' &&
      !pathname.startsWith('/reset-password')
    ) {
      navigate('/login', { replace: true });
    } else if (isAuthenticated && (pathname === '/login' || pathname === '/register')) {
      navigate('/', { replace: true });
    }
  }, [isAuthenticated, pathname, loadingStates.userLoading, navigate]);

  return (
    <div className="bg-zinc-50 dark:bg-stone-900">
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default LoginLayout;
