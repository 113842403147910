import { faFileArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import api from '../../../../../services/api';
import Button from '../../../../common/Button';
import { useNotification } from '../../../../../context/NotificationContext';
import { NotificationType, Position } from '../../../../../types/enums/notificationEnums';
import { useTranslation } from 'react-i18next';

interface UploadLogoProps {
  currentLogo: string;
  onLogoUpdate: (newLogoUrl: string) => void;
}

const UploadLogo: React.FC<UploadLogoProps> = ({ currentLogo, onLogoUpdate }) => {
  const { t } = useTranslation();  // i18n hook
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(currentLogo);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const { showNotification } = useNotification();

  useEffect(() => {
    if (currentLogo) {
      setPreview(currentLogo);
    }
  }, [currentLogo]);

  const handleFileDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length) {
      handleFileSelection(files[0]);
    }
  }, []);

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length) {
      handleFileSelection(files[0]);
    }
  };

  const handleFileSelection = (selectedFile: File) => {
    if (selectedFile.size > 15 * 1024 * 1024) {
      showNotification(
        t('UploadLogo.FileSizeError'), 
        NotificationType.Error, 
        Position.TopRight
      );
      return;
    }

    setFile(selectedFile);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result as string);
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleSubmit = async () => {
    if (file) {
      try {
        setIsUploading(true);
        const formData = new FormData();
        formData.append('logo', file);

        const response = await api.post('/admin/upload-logo', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });

        if (response.data && response.data.logo_url) {
          onLogoUpdate(response.data.logo_url);
        }
        showNotification(
          t('UploadLogo.SuccessMessage'), 
          NotificationType.Success, 
          Position.TopRight
        );
      } catch (error) {
        console.error('Failed to upload logo:', error);
        showNotification(
          t('UploadLogo.ErrorMessage'), 
          NotificationType.Error, 
          Position.TopRight
        );
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <div className="flex flex-col md:flex-row w-full gap-6 md:gap-12">
      <div className="w-full md:w-1/2">
        <h4 className="text-stone-900 dark:text-zinc-300 font-semibold leading-snug mb-2">
          {t('UploadLogo.LogoSelection')}
        </h4>
        <div
          className="w-full h-48 py-6 bg-slate-100 dark:bg-stone-600 rounded-2xl border-2 border-slate-400 dark:border-stone-500 gap-3 grid border-dashed"
          onDrop={handleFileDrop}
          onDragOver={handleDragOver}
        >
          <div className="grid gap-1">
            <div className="mx-auto">
              <FontAwesomeIcon icon={faFileArrowUp} size="2x" className="text-orange-500" />
            </div>
            <h2 className="text-center text-slate-400 dark:text-zinc-400 text-xs leading-4">
              {t('UploadLogo.FileFormats')}
            </h2>
          </div>
          <div className="grid gap-2">
            <h4 className="text-center text-gray-900 dark:text-zinc-300 text-sm font-medium leading-snug">
              {t('UploadLogo.DragDropText')}
            </h4>
            <div className="flex items-center justify-center">
              <label>
                <input type="file" accept="image/*" onChange={handleFile} hidden />
                <div className="flex w-28 h-9 px-2 flex-col bg-orange-500 rounded-full shadow text-white text-xs font-semibold leading-4 items-center justify-center cursor-pointer focus:outline-none">
                  {t('UploadLogo.ChooseFile')}
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2">
        <h4 className="text-stone-900 dark:text-zinc-300 font-semibold leading-snug mb-2">
          {t('UploadLogo.CurrentLogo')}
        </h4>
        {preview && (
          <div className="w-full h-48 overflow-hidden rounded-2xl flex items-center justify-start">
            <img
              src={preview}
              alt={t('UploadLogo.PreviewAltText')}
              className="max-w-full max-h-full object-contain rounded-2xl"
            />
          </div>
        )}
        {file && (
          <div className="mt-4">
            <p className="text-red-500 text-sm">
              {t('UploadLogo.UploadWarning')}
            </p>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={isUploading}
              className="mt-2"
            >
              {isUploading ? t('UploadLogo.Uploading') : t('UploadLogo.Upload')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadLogo;
