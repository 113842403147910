import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash/debounce';
import { motion, AnimatePresence } from 'framer-motion';

import Categories from './Categories';
import Products from './Products';
import ProductModal from './ProductModal';
import api from '../../../services/api';
import { CategoryData } from '../../../types/homepage';
import ProductsWithoutImage from './ProductWithoutImage';
import { useWebSettings } from '../../../context/WebSettingsContext';
import clsx from 'clsx';
import Spinner from '../../common/Spinner';
import { useTranslation } from 'react-i18next';

import discountimage from '../../../assets/images/discount.png';

const HomePage = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [categories, setCategories] = useState<CategoryData[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
  const [showDiscountModal, setShowDiscountModal] = useState(true);
  const { t } = useTranslation();

  const { webData, loading, openData } = useWebSettings();
  const isImage = webData?.include_image === 1;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await api.get('/products/get-all-categories');
        setCategories(response.data.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  const debouncedSetSearchTerm = useCallback(
    debounce((value: string) => {
      setSearchTerm(value);
    }, 300),
    []
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchTerm(e.target.value);
  };

  const handleProductSelect = (productId: number) => {
    setSelectedProductId(productId);
    setModalOpen(true);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {/* Discount Modal */}
      <AnimatePresence>
        {showDiscountModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
            onClick={() => setShowDiscountModal(false)}
          >
            <motion.div
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.5, opacity: 0 }}
              transition={{ type: 'spring', duration: 0.5 }}
              onClick={e => e.stopPropagation()}
              className="relative w-full max-w-[90%] md:max-w-[500px] lg:max-w-[600px] rounded-lg overflow-hidden"
            >
              <button
                onClick={() => setShowDiscountModal(false)}
                className="absolute top-2 right-2 bg-white dark:bg-stone-800 rounded-full w-8 h-8 flex items-center justify-center text-xl font-bold hover:bg-gray-200 dark:hover:bg-stone-700 transition-colors"
              >
                ×
              </button>
              <img
                src={discountimage}
                alt="Discount Offer"
                className="w-full h-auto object-contain"
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full"
      >
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="flex flex-col gap-6 md:flex-row md:gap-0 justify-center md:justify-between items-center -ml-4 md:-ml-0"
        >
          <div className="flex flex-col gap-1 justify-center items-center">
            <motion.h3
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className="text-black dark:text-white text-4xl font-bold uppercase flex items-start text-center md:text-left"
            >
              {webData?.restaurant_name}
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.6, duration: 0.3, type: 'spring' }}
                className={clsx(
                  'p-1 w-1 h-1 rounded-full',
                  openData?.open ? 'bg-green-500 dark:bg-green-400' : 'bg-red-500 dark:bg-red-400'
                )}
              ></motion.div>
            </motion.h3>
            <motion.p
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.5 }}
              className={clsx(
                'text-xl uppercase text-center md:text-left',
                openData?.open
                  ? 'text-green-500 dark:text-green-400'
                  : 'font-bold text-red-500 dark:text-red-400 text-wrap'
              )}
            >
              {openData?.open
                ? `${t('Open')} ${openData.open_time} - ${openData.close_time}`
                : t('Closed')}
            </motion.p>
          </div>
          <motion.form
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="flex items-center justify-center relative"
          >
            <span className="absolute left-3 text-stone-500 dark:text-stone-400">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            <input
              type="text"
              placeholder="Search dishes..."
              aria-label="Search for dishes"
              onChange={handleSearchChange}
              className="pl-10 pr-4 py-2 text-md border border-gray-400 dark:border-0 md:text-lg rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500"
            />
          </motion.form>
        </motion.div>
        <Categories
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          categories={categories}
        />
        {isImage ? (
          <Products
            selectedCategory={selectedCategory}
            searchTerm={searchTerm}
            categories={categories}
            onProductSelect={handleProductSelect}
          />
        ) : (
          <ProductsWithoutImage
            selectedCategory={selectedCategory}
            searchTerm={searchTerm}
            categories={categories}
            onProductSelect={handleProductSelect}
          />
        )}
      </motion.div>
      {modalOpen && selectedProductId && (
        <ProductModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          productID={selectedProductId}
          categories={categories}
        />
      )}
    </>
  );
};

export default HomePage;
