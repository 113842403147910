import React, { useEffect, useState } from 'react';
import Button from '../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import LabeledInput from '../../../common/LabeledInput';
import api from '../../../../services/api';
import { NotificationType, Position } from '../../../../types/enums/notificationEnums';
import { useNotification } from '../../../../context/NotificationContext';
import Spinner from '../../../common/Spinner';

const Ingredients = () => {
  const { t } = useTranslation();
  const [selectedIng, setSelectedIng] = useState<number | null>(null);
  const [ingredient, setIngredient] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const { showNotification } = useNotification();
  const [ingredients, setIngredients] = useState([]);

  const EditIngredient = () => {
    
  };
  const DeleteIngredient = () => {
    
  };

  const AddIngredient = (newOne: string) => {
    if (newOne) {
      setIngredient('');
    }
  };

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/admin/get-all-ingredients`);
        const fetchedIngredients = response.data.data;
        console.log(fetchedIngredients);
        setIngredients(fetchedIngredients);
        
        
      } catch (error) {
        showNotification(
          t('Error occured fetching orders. Please try again later.'),
          NotificationType.Error,
          Position.TopRight
        );
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  return (
    <div className="w-full text-stone-900 dark:text-zinc-300 pb-4">
      <div>
        <h1 className="flex justify-start items-center pt-4 font-bold text-[40px] text-stone-900 dark:text-orange-600">
          {t("Ingredients")}
        </h1>
        <p>{t("IngredientsDesc")}</p>
      </div>
      <div className="mt-8 flex justify-between items-center">
        <div className="flex flex-row gap-4 w-2/3">
          <div className="w-1/2">
            <LabeledInput
              name="ingredient"
              placeholder={t('Ingredient')}
              id="ingredient"
              width="calc(100% )"
              value={ingredient}
              onChange={e => setIngredient(e.target.value)}
            />
          </div>

          <Button variant="tertiary" onClick={() => AddIngredient(ingredient)} disabled = {!ingredient}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            {t("Add New Ingredient")}
          </Button>
        </div>

        <div className="flex flex-row gap-4 justify-end items-end min-w-[200px]">
          <Button className="w-1/2" variant="tertiary" onClick={() => EditIngredient()} disabled={!selectedIng}>
            <FontAwesomeIcon icon={faEdit} className='text-[20px]'  />
          </Button>
          <Button variant="cancel" className="w-1/2" onClick={() => DeleteIngredient()} disabled={!selectedIng}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
      </div>
      {loading ? (
        <div className="flex items-center justify-center mt-10">
          <Spinner />
        </div>
      ) : (
      <div className="mt-8 w-full">
        <ul className="grid grid-cols-7 gap-4 text-[16px]">
          {ingredients.map((data) => (
            <li
              key={data["id"]}
              className={clsx(
                selectedIng === data["id"] ? 'bg-orange-500 hover:bg-orange-400 text-zinc-200' : 'bg-slate-300 dark:bg-stone-700',
                'py-2 px-4 rounded-xl flex flex-row gap-4 justify-center items-center cursor-pointer hover:bg-slate-400 dark:hover:bg-stone-600 '
              )}
              onClick={() => setSelectedIng(data["id"])}
            >
              {t(data["ingredient_name"])}
            </li>
          ))}
        </ul>
      </div>)}
    </div>
  );
};

export default Ingredients;
