import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface StatusDropdownProps {
  status: string;
  onStatusChange: (status: string) => void;
}

const StatusDropdown: React.FC<StatusDropdownProps> = ({ status, onStatusChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const statuses = ['Pending', 'Delivered', 'Cancelled'];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Delivered':
        return 'text-green-500';
      case 'Pending':
        return 'text-amber-500';
      case 'Cancelled':
        return 'text-red-500';
      default:
        return 'text-slate-600';
    }
  };

  return (
    <div className="hs-dropdown relative inline-flex" ref={dropdownRef}>
      <button
        type="button"
        className={clsx(
          'hs-dropdown-toggle py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg bg-slate-100 border border-slate-300 shadow-sm hover:bg-slate-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700',
          getStatusColor(status)
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        {t(status)}
        <svg
          className={`hs-dropdown-open:rotate-180 size-4 transition-transform ${
            isOpen ? 'rotate-180' : ''
          }`}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </button>
      {isOpen && (
        <div className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-100 min-w-36 bg-slate-100 shadow-md rounded-lg mt-2 dark:bg-neutral-800 dark:border dark:border-neutral-700 dark:divide-neutral-700 absolute z-10 top-full">
          <div className="p-1 space-y-0.5">
            {statuses.map(statusOption => (
              <button
                key={statusOption}
                className={clsx(
                  'w-full flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm hover:bg-slate-200 focus:outline-none focus:bg-gray-100 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 cursor-pointer',
                  getStatusColor(statusOption)
                )}
                onClick={() => {
                  onStatusChange(statusOption);
                  setIsOpen(false);
                }}
              >
                {t(statusOption)}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusDropdown;
