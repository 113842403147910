import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Form from '../../common/Form';
import Button from '../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../../context/AuthContext';
import api from '../../../services/api';
import { useNotification } from '../../../context/NotificationContext';
import { NotificationType, Position } from '../../../types/enums/notificationEnums';
import { isAxiosError } from 'axios';
import GoBackButton from '../../common/GoBackButton';
import Spinner from '../../common/Spinner';

const PasswordUpdate = () => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (newPassword !== confirmPassword) {
      showNotification(
        t('New password and confirm password do not match.'),
        NotificationType.Error,
        Position.TopRight
      );
      setIsSubmitting(false);
      return;
    }
    if (oldPassword === newPassword) {
      showNotification(
        t('Old password and new password can not be same.'),
        NotificationType.Error,
        Position.TopRight
      );
      setIsSubmitting(false);
      return;
    }
    try {
      const response = await api.post('/user/change-password', {
        id: user?.id,
        oldPassword,
        newPassword,
        confirmPassword: confirmPassword
      });

      if (response.data.success) {
        showNotification(
          t('Password updated successfully'),
          NotificationType.Success,
          Position.TopRight
        );
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        showNotification(
          t(response.data.message || 'Failed to update password. Please try again.'),
          NotificationType.Error,
          Position.TopRight
        );
      }
    } catch (error) {
      showNotification(
        t(
          isAxiosError(error) && error.response?.data?.message
            ? error.response.data.message
            : 'Error changing password. Please try again.'
        ),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const UpdateFields = [
    {
      name: 'oldPassword',
      type: 'passwordSet',
      label: t('Current Password'),
      placeholder: t('Current Password'),
      id: 'oldPassword',
      htmlFor: 'oldPassword',
      value: oldPassword,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setOldPassword(e.target.value)
    },
    {
      name: 'newPassword',
      type: 'passwordSet',
      label: t('New Password'),
      placeholder: t('New Password'),
      id: 'newPassword',
      htmlFor: 'newPassword',
      value: newPassword,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)
    },
    {
      name: 'confirmPassword',
      type: 'passwordSet',
      label: t('Confirm Password'),
      placeholder: t('Confirm Password'),
      id: 'confirmPassword',
      htmlFor: 'confirmPassword',
      value: confirmPassword,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)
    }
  ];

  return (
    <div className="flex flex-col md:flex-row w-full md:w-full h-auto md:h-auto px-4 md:px-0 ">
      <div className="w-full lg:w-3/5 bg-slate-200 dark:bg-stone-800 flex flex-col h-full rounded-md px-4 md:px-8">
        <div className="mt-4">
          <h1 className="py-2 font-semibold text-stone-900 dark:text-orange-600">
            {t('Password Update')}
          </h1>
          <div className="pb-4 h-full rounded-md block lg:hidden">
            <p className="text-stone-600 dark:text-gray-300 text-sm md:text-base">
              {t('passwordUpdateDesc')}
            </p>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="-mt-4 w-full">
          <Form fields={UpdateFields} formId="password-update-form" onSubmit={handleSubmit} />
          <div className="my-3 flex flex-row gap-2 justify-end items-center w-full h-14">
            <Button
              type="button"
              variant="cancel"
              className="w-full h-auto text-[14.5px]"
              disabled={isSubmitting}
            >
              {t('Cancel')}
            </Button>
            <Button
              type="submit"
              variant="tertiary"
              className="w-full h-auto text-[14.5px]"
              disabled={isSubmitting}
            >
              {isSubmitting ? <Spinner size="sm" className="w-5 h-5" /> : t('Save Changes')}
            </Button>
          </div>
        </form>
      </div>

      <div className="lg:w-2/5 ml-4 p-8 bg-slate-200 dark:bg-stone-800 h-full rounded-md hidden lg:block">
        <div className="w-full flex justify-center items-center mb-8">
          <FontAwesomeIcon
            icon={faLock}
            className="w-16 h-16 text-stone-900 dark:text-orange-600"
          />
        </div>

        <p className="text-stone-600 dark:text-gray-300 text-sm md:text-base">
          {t('passwordUpdateDesc')}
        </p>
      </div>
      <GoBackButton
        to="/settings"
        className="block md:hidden mt-6 dark:text-stone-200 text-stone-800"
      >
        <span className="text-[17px] ">{t('Turn back to settings')}</span>
      </GoBackButton>
    </div>
  );
};

export default PasswordUpdate;
