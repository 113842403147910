import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Button from '../../common/Button';
import clsx from 'clsx';

import { OrderSummaryProps } from '../../../types/payment';
import { useCart } from '../../../context/CartContext';
import { useNavigate } from 'react-router-dom';

const OrderSummary: React.FC<OrderSummaryProps> = ({
  orderDetails,
  paymentType,
  selectedAddress,
  onCompleteOrder
}) => {
  const { t } = useTranslation();
  const { calculateDiscountedPrice } = useCart();
  const navigate = useNavigate();

  const totalPrice = orderDetails?.reduce((sum, item) => sum + item.price * item.quantity, 0) || 0;
  const totalQuantity = orderDetails?.reduce((sum, item) => sum + item.quantity, 0) || 0;

  const totalDiscount =
    orderDetails?.reduce((sum, item) => {
      const originalPrice = item.price * item.quantity;
      const discountedPrice =
        calculateDiscountedPrice(item.price, item.product_discount, item.category_discount) *
        item.quantity;
      return sum + (originalPrice - discountedPrice);
    }, 0) || 0;

  const MIN_ORDER_AMOUNT = 15;
  const DELIVERY_FEE = 3;
  const subtotal = totalPrice - totalDiscount;
  const deliveryFee = subtotal < MIN_ORDER_AMOUNT ? DELIVERY_FEE : 0;
  const total = subtotal + deliveryFee;

  return (
    <div className="w-full lg:w-full">
      <div className="bg-slate-200 dark:bg-stone-800 border border-slate-300 dark:border-stone-700 rounded-xl p-6 sticky top-8 flex flex-col justify-between shadow-sm shadow-slate-300 dark:shadow-none">
        <div>
          <h2 className="text-xl md:text-2xl font-semibold mb-4 text-stone-900 dark:text-orange-400">
            {t('Order Summary')}
          </h2>
          {!orderDetails ? (
            <div>No order summary available.</div>
          ) : (
            <>
              <div className="space-y-2 text-base md:text-lg">
                {orderDetails?.map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center text-stone-900 dark:text-zinc-200"
                  >
                    <span>
                      {item.product_name} (x{item.quantity})
                    </span>
                    <span>{Number(item.price * item.quantity).toFixed(2)}€</span>
                  </div>
                ))}
                <div
                  className={clsx(
                    'pt-2 border-t border-slate-400 dark:border-stone-600 text-stone-900 dark:text-zinc-200',
                    (totalQuantity === 1 || totalDiscount === 0) && 'border-none'
                  )}
                >
                  {(totalQuantity !== 1 || totalDiscount > 0) && (
                    <div className="flex justify-between items-center">
                      <span>
                        {t('Subtotal')} ({totalQuantity}{' '}
                        {totalQuantity === 1 ? t('item') : t('items')})
                      </span>
                      <span>{totalPrice.toFixed(2)}€</span>
                    </div>
                  )}
                  {totalDiscount > 0 && (
                    <div className="flex justify-between items-center text-green-500">
                      <span>{t('Discount')}</span>
                      <span>-{totalDiscount.toFixed(2)}€</span>
                    </div>
                  )}
                  {subtotal < MIN_ORDER_AMOUNT && (
                    <div className="p-3 bg-orange-100 dark:bg-orange-900 rounded-lg mb-4">
                      <h3 className="text-orange-800 dark:text-orange-200 font-semibold mb-1">
                        {t('Small order fee')}
                      </h3>
                      <p className="text-orange-700 dark:text-orange-300 text-sm">
                        {t('A delivery fee of €3 will be added to orders under €15')}
                      </p>
                    </div>
                  )}
                  {deliveryFee > 0 && (
                    <div className="flex justify-between items-center text-stone-900 dark:text-zinc-200">
                      <span>{t('Delivery fee')}</span>
                      <span>{deliveryFee.toFixed(2)}€</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-4 pt-4 border-t border-gray-700">
                <div className="flex justify-between items-center text-xl font-bold text-stone-900 dark:text-zinc-200">
                  <span>{t('Total')}</span>
                  <span className="text-orange-500">{total.toFixed(2)}€</span>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex justify-center mt-8 ">
          <Button
            disabled={selectedAddress === null || paymentType === 'card' || !orderDetails}
            variant={'tertiary'}
            onClick={onCompleteOrder}
            className={clsx(
              'flex-grow flex w-full items-center justify-center px-4 py-2 select-none',
              (selectedAddress === null || paymentType === 'card') &&
                'opacity-50 cursor-not-allowed'
            )}
          >
            {t('Complete Order')}
            <FontAwesomeIcon icon={faAngleRight} className="w-4 h-4 ml-2" />
          </Button>
        </div>
        <Button onClick={() => navigate('/')} variant="cancel" className="mt-4 select-none">
          {t('Cancel Order')}
        </Button>
      </div>
    </div>
  );
};

export default OrderSummary;
