import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../context/AuthContext';
import { useNotification } from '../../../../context/NotificationContext';
import { NotificationType, Position } from '../../../../types/enums/notificationEnums';

const useRegisterPageLogic = () => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [modalState, setModalState] = useState({
    isPrivacyPolicyModalOpen: false,
    isTermsOfUseModalOpen: false
  });
  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string }>({});
  const [agreement, setAgreement] = useState(false);
  const navigate = useNavigate();
  const { register, loadingStates } = useAuth();

  const handleModalToggle = (modalName: string, isOpen: boolean) => {
    setModalState(prevState => ({
      ...prevState,
      [modalName]: isOpen
    }));
  };

  const validateForm = (
    email: string,
    password: string,
    confirmPassword: string,
    agreement: boolean
  ) => {
    const errors: { [key: string]: string } = {};
    if (!email) errors.email = t('Email is required');
    if (!password) errors.password = t('Password is required');
    if (!confirmPassword) errors.confirmPassword = t('Please enter your password again');
    if (password !== confirmPassword) errors.confirmPassword = t('Passwords do not match');

    if (email && password && confirmPassword && password === confirmPassword && !agreement) {
      errors.agreement = t('You must agree to the terms of use and privacy policy');
    }
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = validateForm(email, password, confirmPassword, agreement);
    setErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      const errorMessage = await register(email, password, confirmPassword);
      if (errorMessage) {
        setErrorMessages({ ...errors, general: t(errorMessage) });
      } else {
        navigate('/');
        showNotification(
          t('You have been registered successfully.'),
          NotificationType.Success,
          Position.TopRight
        );
      }
    }
  };

  const handleAgreementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAgreement(e.target.checked);
    setErrorMessages(prevErrors => {
      const { agreement, ...rest } = prevErrors;
      return rest;
    });
  };

  const handleGoogleSignInClick = () => {};

  const handleAppleSignInClick = () => {};

  const fields = [
    {
      name: 'email',
      type: 'email',
      label: t('Email'),
      placeholder: t('Email'),
      id: 'register-email',
      htmlFor: 'register-email',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
    },
    {
      name: 'password',
      type: 'password',
      label: t('Password'),
      placeholder: t('Password'),
      id: 'register-password',
      htmlFor: 'register-password',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
    },
    {
      name: 'confirmPassword',
      type: 'password',
      label: t('Confirm Password'),
      placeholder: t('Confirm Password'),
      id: 'register-confirmPassword',
      htmlFor: 'register-confirmPassword',
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)
    }
  ];

  return {
    t,
    email,
    password,
    confirmPassword,
    modalState,
    errorMessages,
    agreement,
    loadingStates,
    handleModalToggle,
    handleSubmit,
    handleAgreementChange,
    handleGoogleSignInClick,
    handleAppleSignInClick,
    fields
  };
};

export default useRegisterPageLogic;
