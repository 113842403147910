import React, { useState, useEffect } from 'react';
import { Shield, Cookie, X, ChevronDown } from 'lucide-react';
import Button from './Button';
import { useConsent } from '../../context/CookieConsentContext';

const CookieConsentBanner = () => {
  const { consentGiven, setConsent } = useConsent();
  const [isVisible, setIsVisible] = useState(!consentGiven); // Only show if consent hasn't been given
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  useEffect(() => {
    // Check local storage and update visibility accordingly
    if (consentGiven) {
      setIsVisible(false); // Hide the banner if consent is already given
    } else {
      setIsVisible(true); // Show the banner if consent hasn't been given
    }
  }, [consentGiven]);

  const handleAccept = () => {
    console.log('Cookies accepted');
    document.cookie = 'tracking=enabled; max-age=31536000; path=/';
    setConsent(true); // Store consent in context and localStorage
    setIsVisible(false);
  };

  const handleDecline = () => {
    console.log('Cookies declined');
    document.cookie = 'tracking=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    setConsent(false); // Store consent in context and localStorage
    setIsVisible(false);
  };

  if (!isVisible) return null; // Don't render if not visible

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-slate-200 dark:bg-stone-900 shadow-lg z-50 transition-all duration-300 border-t border-slate-300 dark:border-stone-800">
      <div className="max-w-6xl mx-auto px-4 py-4">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-3">
          {/* Content Section */}
          <div className="flex-1">
            <div className="flex items-center gap-2 mb-1.5">
              <Cookie className="h-4 w-4 text-orange-600" />
              <h2 className="text-base font-medium text-black dark:text-stone-100">
                Cookie Settings
              </h2>
            </div>

            <p className="text-sm text-gray-700 dark:text-stone-300 mb-2">
              We use cookies to improve your experience and analyze site traffic.
            </p>

            {/* Expandable Details */}
            {isDetailsOpen && (
              <div className="mb-3 space-y-2 text-xs pl-4 border-l border-stone-700">
                <div className="flex items-start gap-2">
                  <Shield className="h-3 w-3 mt-0.5 text-orange-600" />
                  <div>
                    <p className="font-medium text-black dark:text-stone-200">Essential Cookies</p>
                    <p className="text-gray-700 dark:text-stone-400">
                      Required for basic functionality
                    </p>
                  </div>
                </div>
                <div className="flex items-start gap-2">
                  <Cookie className="h-3 w-3 mt-0.5 text-orange-600" />
                  <div>
                    <p className="font-medium text-black dark:text-stone-200">Analytics</p>
                    <p className="text-gray-700 dark:text-stone-400">Help us improve our website</p>
                  </div>
                </div>
              </div>
            )}

            <button
              onClick={() => setIsDetailsOpen(!isDetailsOpen)}
              className="text-xs text-orange-500 hover:text-orange-400 transition-colors flex items-center gap-1"
            >
              <span>{isDetailsOpen ? 'Hide' : 'Show'} Details</span>
              <ChevronDown
                className={`h-3 w-3 transition-transform ${isDetailsOpen ? 'rotate-180' : ''}`}
              />
            </button>
          </div>

          {/* Buttons Section */}
          <div className="flex gap-2 mt-2 md:mt-0">
            <Button onClick={handleDecline} variant="cancel">
              Decline
            </Button>
            <Button onClick={handleAccept}>Accept</Button>
          </div>

          {/* Close Button */}
          <button
            onClick={() => setIsVisible(false)}
            className="absolute top-3 right-3 text-stone-500 hover:text-stone-300 transition-colors"
            aria-label="Close cookie settings"
          >
            <X className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsentBanner;
