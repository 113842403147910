import React, { useState, useEffect } from 'react';
import Button from '../../../../common/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faTrash } from '@fortawesome/free-solid-svg-icons';
import HistoryModal from './HistoryModal';
import DeleteWarning from '../../common/DeleteWarning';
import api from '../../../../../services/api';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../../common/Spinner';

interface User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  phone_number: string;
  created_at: string;
}

const Listusers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [historyModal, setHistoryModal] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await api.get('/admin/get-all-users');
      setUsers(response.data.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to fetch users. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleHistory = (userId: number) => {
    setSelectedUserId(userId);
    setHistoryModal(true);
  };

  const handleWarning = (userId: number) => {
    setSelectedUserId(userId);
    setWarningModal(true);
  };

  const handleDeleteUser = async () => {
    if (selectedUserId) {
      try {
        await api.delete(`/admin/delete-user/${selectedUserId}`);
        setUsers(users.filter(user => user.id !== selectedUserId));
        setWarningModal(false);
        setSelectedUserId(null);
      } catch (err) {
        console.error('Error deleting user:', err);
        // Optionally, you can set an error state here to show an error message to the user
      }
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="w-full text-stone-900 dark:text-zinc-300 pb-4">
      <div>
        <h1 className="flex justify-start items-center pt-4 font-bold text-[40px] text-stone-900 dark:text-orange-600">
          {t("Listusers.Title")}
        </h1>
        <p>
        {t("Listusers.Description")}
        </p>
      </div>
      {loading ? (
        <div className="flex items-center justify-center mt-10">
          <Spinner />
        </div>
      ) :(
      <div className="h-full mt-8">
        <div className="w-full h-full overflow-scroll rounded-md ">
          <table className="w-full table-fixed border-collapse">
            <thead className="bg-slate-300 dark:bg-stone-800">
              <tr>
                <th className="w-1/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t("Id")}
                </th>
                <th className="w-2/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t("Name")}
                </th>
                <th className="w-3/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t("Email")}
                </th>
                <th className="w-1/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t("Role")}
                </th>
                <th className="w-2/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t("Phone Number")}
                </th>
                <th className="w-2/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                  {t("Joined Date")}
                </th>
                <th className="w-2/12 px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                {t("Actions")}
                </th>
              </tr>
            </thead>
            <tbody className="text-stone-900 dark:text-zinc-300 bg-slate-200 dark:bg-stone-700">
              {users.map(user => (
                <tr key={user.id} className="hover:bg-slate-300 dark:hover:bg-stone-600 ">
                  <td className="py-2 border border-gray-600 dark:border-gray-400 text-center">
                    {user.id}
                  </td>
                  <td className="pl-2 py-2 border border-gray-600 dark:border-gray-400">
                    {`${user.first_name} ${user.last_name}`}
                  </td>
                  <td className="pl-2 py-2 border border-gray-600 dark:border-gray-400">
                    {user.email}
                  </td>
                  <td className="pl-2 py-2 border border-gray-600 dark:border-gray-400">
                    {user.role}
                  </td>
                  <td className="pl-2  py-2 border border-gray-600 dark:border-gray-400">
                    {user.phone_number}
                  </td>
                  <td className="pl-2  py-2 border border-gray-600 dark:border-gray-400">
                    {new Date(user.created_at).toLocaleDateString()}
                  </td>
                  <td className="px-3 py-2 border border-gray-600 dark:border-gray-400">
                    <div className="flex flex-row w-full gap-4">
                      <Button
                        className="w-1/2"
                        variant="tertiary"
                        onClick={() => handleHistory(user.id)}
                      >
                        <FontAwesomeIcon icon={faClockRotateLeft} />
                      </Button>
                      <Button
                        variant="cancel"
                        className="w-1/2"
                        onClick={() => handleWarning(user.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <HistoryModal
          modalOpen={historyModal}
          setModalOpen={setHistoryModal}
          userId={selectedUserId}
        />
        <DeleteWarning
          modalOpen={warningModal}
          setModalOpen={setWarningModal}
          onConfirm={handleDeleteUser}
          description={t('Listusers.ConfirmDelete')}
        />
      </div>)}
    </div>
  );
};

export default Listusers;
