import React from 'react';
import Form from '../../../common/Form';
import { loginPageImage, appleLogo, googleLogo } from '../../../../assets/images/index';
import Modal from '../../../common/Modal';
import GoBackButton from '../../../common/GoBackButton';
import { AlertCircle } from 'lucide-react';
import useRegisterPageLogic from './registerPage.logic';
import { motion, AnimatePresence } from 'framer-motion';
import Button from '../../../common/Button';

const RegisterPage: React.FC = () => {
  const {
    t,
    modalState,
    errorMessages,
    agreement,
    loadingStates: { register: loading },
    handleModalToggle,
    handleSubmit,
    handleAgreementChange,
    handleGoogleSignInClick,
    handleAppleSignInClick,
    fields
  } = useRegisterPageLogic();

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="flex flex-col lg:flex-row w-full h-screen justify-center items-center"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        className="relative w-full lg:w-1/2 h-64 lg:h-full hidden lg:flex items-center justify-center"
      >
        <img
          className="absolute w-full h-full object-cover lg:max-h-full select-none"
          src={loginPageImage}
          alt="login"
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.4, duration: 0.5 }}
        className="relative w-full flex items-center justify-center lg:w-1/2 p-4 lg:p-8"
      >
        <div className="absolute left-4 hidden lg:block">
          <GoBackButton
            to="/login"
            className="text-slate-400 hover:text-slate-500 dark:text-zinc-300 dark:hover:text-zinc-100"
            classNameForIcon="w-20 h-20"
          />
        </div>
        <div className="w-full lg:w-3/4 max-w-sm">
          <Form
            title={t('Register')}
            customTitleClass='text-[54px]'
            description={t('Create your account')}
            fields={fields}
            onSubmit={handleSubmit}
            errorMessages={errorMessages}
            formId="register-form"
            loading={loading}
          >
            <div className="mt-4">
              <div className="flex items-center">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="agreement"
                    className="checkbox-custom"
                    onChange={handleAgreementChange}
                    checked={agreement}
                    disabled={loading}
                  />
                </div>
                <label
                  className="ml-2 font-medium text-base text-justify text-stone-900 dark:text-zinc-300 cursor-pointer"
                  htmlFor="agreement"
                >
                  {t('I agree to')}{' '}
                  <button
                    type="button"
                    className="text-blue-500 underline"
                    onClick={() => handleModalToggle('isTermsOfUseModalOpen', true)}
                  >
                    {t('terms of use')}
                  </button>
                  {t('and')}{' '}
                  <button
                    type="button"
                    className="text-blue-500 underline"
                    onClick={() => handleModalToggle('isPrivacyPolicyModalOpen', true)}
                  >
                    {t('privacy policy')}
                  </button>
                  {t('.')}
                </label>
              </div>
              {errorMessages.agreement && (
                <div className="flex items-center mt-1 text-red-500">
                  <AlertCircle className="h-4 w-4 mr-1" />
                  <p className="text-xs">{errorMessages.agreement}</p>
                </div>
              )}
            </div>
            {errorMessages.general && (
              <div className="flex items-center mt-4 text-red-500">
                <AlertCircle className="h-4 w-4 mr-1" />
                <p className="text-sm">{errorMessages.general}</p>
              </div>
            )}
            <div className="mt-4 flex flex-col gap-y-4">
              <Button
                variant="tertiary"
                className="py-2 w-full text-lg font-bold"
                type="submit"
                form="register-form"
                disabled={loading}
              >
                {loading ? t('Registering...') : t('Register')}
              </Button>
            </div>
            <div className="flex items-center justify-center my-4">
              <div className="border-t border-slate-400 dark:border-gray-300 flex-grow"></div>
              <span className="px-4 text-gray-500 dark:text-gray-400 pointer-events-none select-none">
                {t('or')}
              </span>
              <div className="border-t border-slate-400 dark:border-gray-300 flex-grow"></div>
            </div>
            <div className="mt-4 flex flex-col gap-y-2">
              <button
                className="bg-white w-full flex items-center justify-center gap-4 border border-slate-300 dark:border-gray-500 rounded-xl p-2 text-gray-900 text-md font-bold shadow-sm shadow-slate-400 dark:shadow-white select-none cursor-not-allowed opacity-50"
                type="button"
                onClick={handleGoogleSignInClick}
                disabled={loading}
              >
                <img className="w-6 h-6" src={googleLogo} alt="google" />
                <span className="flex-1 text-center">{t('Sign in with Google')}</span>
              </button>
              <button
                className="bg-white w-full flex items-center justify-center gap-4 border border-slate-300 dark:border-gray-500 rounded-xl p-2 text-gray-900 text-md font-bold shadow-sm shadow-slate-400 dark:shadow-white select-none cursor-not-allowed opacity-50"
                type="button"
                onClick={handleAppleSignInClick}
                disabled={loading}
              >
                <img className="w-6 h-6" src={appleLogo} alt="google" />
                <span className="flex-1 text-center">{t('Sign in with Apple')}</span>
              </button>
            </div>
          </Form>
        </div>
      </motion.div>
      <Modal
        isOpen={modalState.isTermsOfUseModalOpen}
        onClose={() => handleModalToggle('isTermsOfUseModalOpen', false)}
        title="Terms of Use"
        className="w-full h-full md:max-w-2xl"
      >
        <div className="text-justify font-light leading-relaxed text-zinc-300">
          <p className="mb-4">
            {t('By accessing or using our Services, you agree to the following terms:')}
          </p>

          <ol className="list-decimal list-inside space-y-4">
            <li>
              <span className="font-bold">{t('Acceptance of Terms')}</span>
              <p className="ml-6 mt-1">
                {t(
                  'By using Solylock, you agree to be bound by these Terms and our Privacy Policy. If you do not agree, please discontinue use.'
                )}
              </p>
            </li>

            <li>
              <span className="font-bold">{t('Eligibility')}</span>
              <p className="ml-6 mt-1">{t('You must be 18 years or older to use our Services.')}</p>
            </li>

            <li>
              <span className="font-bold">{t('Account Registration')}</span>
              <p className="ml-6 mt-1">
                {t(
                  'You are responsible for the confidentiality of your account and for all activities that occur under your account.'
                )}
              </p>
            </li>

            <li>
              <span className="font-bold">{t('Use of Services')}</span>
              <p className="ml-6 mt-1">
                {t(
                  'Use the Services for lawful purposes only. Do not engage in any behavior that could harm Solylock or other users.'
                )}
              </p>
            </li>

            <li>
              <span className="font-bold">{t('Intellectual Property')}</span>
              <p className="ml-6 mt-1">
                {t(
                  'All content on Solylock is the property of Solylock or its licensors. Unauthorized use is prohibited.'
                )}
              </p>
            </li>

            <li>
              <span className="font-bold">{t('User Content')}</span>
              <p className="ml-6 mt-1">
                {t(
                  'By submitting content, you grant Solylock a license to use, display, and distribute your content as part of the Services.'
                )}
              </p>
            </li>
            <li>
              <span className="font-bold">{t('Use of Services')}</span>
              <p className="ml-6 mt-1">
                {t(
                  'Use the Services for lawful purposes only. Do not engage in any behavior that could harm Solylock or other users.'
                )}
              </p>
            </li>

            <li>
              <span className="font-bold">{t('Intellectual Property')}</span>
              <p className="ml-6 mt-1">
                {t(
                  'All content on Solylock is the property of Solylock or its licensors. Unauthorized use is prohibited.'
                )}
              </p>
            </li>

            <li>
              <span className="font-bold">{t('User Content')}</span>
              <p className="ml-6 mt-1">
                {t(
                  'By submitting content, you grant Solylock a license to use, display, and distribute your content as part of the Services.'
                )}
              </p>
            </li>
            <li>
              <span className="font-bold">{t('Use of Services')}</span>
              <p className="ml-6 mt-1">
                {t(
                  'Use the Services for lawful purposes only. Do not engage in any behavior that could harm Solylock or other users.'
                )}
              </p>
            </li>
          </ol>
        </div>
      </Modal>
      <Modal
        isOpen={modalState.isPrivacyPolicyModalOpen}
        onClose={() => handleModalToggle('isPrivacyPolicyModalOpen', false)}
        title={t('Privacy Policy')}
        className="w-full h-full md:max-w-2xl"
      >
        <div className="text-justify font-light leading-relaxed text-zinc-300">
          <p className="mb-4">
            {t('By accessing or using our Services, you agree to the following terms:')}
          </p>

          <ol className="list-decimal list-inside space-y-4">
            <li>
              <span className="font-bold">{t('Acceptance of Terms')}</span>
              <p className="ml-6 mt-1">
                {t(
                  'By using Solylock, you agree to be bound by these Terms and our Privacy Policy. If you do not agree, please discontinue use.'
                )}
              </p>
            </li>

            <li>
              <span className="font-bold">{t('Eligibility')}</span>
              <p className="ml-6 mt-1">{t('You must be 18 years or older to use our Services.')}</p>
            </li>

            <li>
              <span className="font-bold">{t('Account Registration')}</span>
              <p className="ml-6 mt-1">
                {t(
                  'You are responsible for the confidentiality of your account and for all activities that occur under your account.'
                )}
              </p>
            </li>

            <li>
              <span className="font-bold">{t('Use of Services')}</span>
              <p className="ml-6 mt-1">
                {t(
                  'Use the Services for lawful purposes only. Do not engage in any behavior that could harm Solylock or other users.'
                )}
              </p>
            </li>

            <li>
              <span className="font-bold">{t('Intellectual Property')}</span>
              <p className="ml-6 mt-1">
                {t(
                  'All content on Solylock is the property of Solylock or its licensors. Unauthorized use is prohibited.'
                )}
              </p>
            </li>

            <li>
              <span className="font-bold">{t('User Content')}</span>
              <p className="ml-6 mt-1">
                {t(
                  'By submitting content, you grant Solylock a license to use, display, and distribute your content as part of the Services.'
                )}
              </p>
            </li>
          </ol>
        </div>
      </Modal>
    </motion.div>
  );
};

export default RegisterPage;
