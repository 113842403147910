import React, { useState } from 'react';
import { FormProps } from '../../types/form';
import clsx from 'clsx';
import { Eye, EyeOff, AlertCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const Form: React.FC<FormProps> = ({
  title,
  description,
  formId,
  fields,
  onSubmit,
  errorMessages,
  children,
  loading,
  customTitleClass = "text-4xl md:text-5xl"
}) => {
  const [passwordVisibility, setPasswordVisibility] = useState<{
    [key: string]: boolean;
  }>({});

  const togglePasswordVisibility = (fieldId: string) => {
    setPasswordVisibility(prev => ({
      ...prev,
      [fieldId]: !prev[fieldId]
    }));
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full"
    >
        <h1 className={clsx("text-stone-900 dark:text-zinc-200 font-semibold text-center", customTitleClass)}>
          {title}
        </h1>
      <div className="mt-8">
        <p className="text-md text-stone-900 dark:text-zinc-200 font-semibold">{description}</p>
        <form id={formId} onSubmit={onSubmit} className="space-y-4 mt-4">
          {fields.map((field, index) => (
            <motion.div
              key={field.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative"
            >
              <div className="relative">
                <input
                  type={
                    (field.type === 'password' || field.type === 'passwordSet') &&
                    passwordVisibility[field.id ?? '']
                      ? 'text'
                      : field.type === 'passwordSet'
                      ? 'password'
                      : field.type
                  }
                  name={field.name}
                  placeholder={field.placeholder}
                  value={field.value}
                  id={field.id}
                  disabled={loading}
                  className={clsx(
                    'peer floating-input-custom relative',
                    errorMessages?.[field.name] && 'bg-red-200 shadow-red-400'
                  )}
                  onChange={field.onChange}
                />
                {(field.type === 'password' || field.type === 'passwordSet') && (
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center justify-center px-3 cursor-pointer text-slate-400 rounded-e-md focus:outline-none focus:text-orange-600 dark:text-stone-400 dark:focus:text-orange-500 hover:text-orange-500 dark:hover:text-orange-500"
                    onClick={() => togglePasswordVisibility(field.id ?? '')}
                  >
                    {passwordVisibility[field.id ?? ''] ? (
                      <Eye className="shrink-0 size-5" />
                    ) : (
                      <EyeOff className="shrink-0 size-5" />
                    )}
                  </button>
                )}
                <label
                  htmlFor={field.htmlFor}
                  className={clsx(
                    'floating-label-custom',
                    errorMessages?.[field.name] && 'text-red-500 peer-focus:text-red-500'
                  )}
                >
                  {field.label}
                </label>
              </div>
              <AnimatePresence>
                {errorMessages && errorMessages[field.name] && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                    className="flex items-center mt-1 text-red-500"
                  >
                    <AlertCircle className="h-4 w-4 mr-1" />
                    <p className="text-xs">{errorMessages[field.name]}</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </form>
        {children}
      </div>
    </motion.div>
  );
};

export default Form;
