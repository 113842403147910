import React from 'react';
import { SvgIconProps } from '../../../types/svg';

const LoginIcon: React.FC<SvgIconProps> = ({
  color = '#FFFFFF',
  height = '32px',
  width = '32px',
  onClick,
  className
}) => (
  <svg
    className={className}
    fill="none"
    height={height}
    width={width}
    onClick={onClick}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />

    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

    <g id="SVGRepo_iconCarrier" transform="rotate(180 12 12)">
      <path
        d="M17 12L10.5 12"
        stroke={color}
        strokeWidth="1.2600000000000002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 9L10.5 12"
        stroke={color}
        strokeWidth="1.2600000000000002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 15L10.5 12"
        stroke={color}
        strokeWidth="1.2600000000000002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 17C17 19.2091 15.2091 20 13 20H10C7.79086 20 6 18.2091 6 16V8C6 5.79086 7.79086 4 10 4H13C15.2091 4 17 4.79086 17 7"
        stroke={color}
        strokeWidth="1.2600000000000002"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default LoginIcon;