import React, { useCallback, useEffect, useRef } from 'react';
import Button from '../../../common/Button';
import { X } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

interface WarningModalProps {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  onConfirm: () => void;
  description: string;
}

const DeleteWarning: React.FC<WarningModalProps> = ({
  modalOpen,
  setModalOpen,
  onConfirm,
  description
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const {t} = useTranslation();
  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        closeModal();
      }
    };

    if (modalOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.body.style.overflow = 'auto';
    };
  }, [modalOpen, closeModal]);

  if (!modalOpen) return null;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-slate-300 bg-opacity-50 dark:bg-black dark:bg-opacity-50 p-4 ">
      <div
        ref={modalRef}
        className="bg-slate-200 dark:bg-stone-900 rounded-lg shadow-lg min-w-[400px]  max-w-full md:h-auto max-h-full flex flex-col"
      >
        <div className="flex justify-end items-end p-4">
          <button
            onClick={closeModal}
            className="text-stone-800 dark:text-zinc-400 hover:text-stone-400 dark:hover:text-zinc-200 transition-colors "
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <div className="flex flex-col justify-center items-center gap-4">
          <FontAwesomeIcon icon={faTriangleExclamation} className="text-[70px]" />
          <div className="flex justify-center items-center text-center text-[18px] w-[400px] px-12">
            <p>{description}</p>
          </div>
          <div className="flex flex-row gap-4 py-4">
            <Button variant="cancel" onClick={handleConfirm}>
              {t("Yes, I am sure")}
            </Button>
            <Button variant="tertiary" onClick={() => setModalOpen(false)}>
              {t("No, cancel")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteWarning;
