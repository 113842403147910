import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faBuilding, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

interface AddressTypeSelectorProps {
  placeKind: string;
  setPlaceKind: (kind: string) => void;
}

const AddressTypeSelector: React.FC<AddressTypeSelectorProps> = ({ placeKind, setPlaceKind }) => {
  const { t } = useTranslation();

  const addressTypes = [
    { type: 'Home', icon: faHouse, label: t('Home') },
    { type: 'Business', icon: faBuilding, label: t('Workplace') },
    { type: 'Other', icon: faEllipsis, label: t('Other') }
  ];

  return (
    <div className="flex flex-row gap-2 mb-4 text-[16px] min-h-12 cursor-pointer w-full">
      {addressTypes.map(({ type, icon, label }) => (
        <div
          key={type}
          className={clsx(
            'bg-slate-300 dark:bg-stone-700 flex flex-row justify-start items-center px-2 rounded-xl w-full text-stone-900 dark:text-zinc-300',
            placeKind === type && 'bg-slate-400 dark:bg-orange-800 dark:bg-opacity-50'
          )}
          onClick={() => setPlaceKind(type)}
        >
          <FontAwesomeIcon
            icon={icon}
            className={clsx(placeKind === type && 'text-orange-600 dark:text-orange-400', 'mr-2')}
          />
          <p
            className={clsx(placeKind === type && 'text-orange-600 dark:text-orange-400', 'w-full')}
          >
            {label}
          </p>
        </div>
      ))}
    </div>
  );
};

export default AddressTypeSelector;
