import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

interface LabeledInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  width?: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
  type?: 'text' | 'birthDay';
  options?: string[];
  error?: string;
}

const LabeledInput: React.FC<LabeledInputProps> = ({
  name,
  placeholder,
  id,
  width,
  value,
  onChange,
  type = 'text',
  options,
  error
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');

  useEffect(() => {
    const currentDate = new Date();
    const min = new Date(
      currentDate.getFullYear() - 120,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const max = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    setMinDate(min.toISOString().split('T')[0]);
    setMaxDate(max.toISOString().split('T')[0]);
  }, []);

  const handleDateInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = event.target.value;
    const dateObject = dateValue ? new Date(dateValue) : null;
    setSelectedDate(dateObject);
  };

  return (
    <div className="relative" style={{ width: width || 'auto' }}>
      {type === 'birthDay' && (
        <input
          type="date"
          value={selectedDate ? selectedDate.toISOString().split('T')[0] : ''}
          onChange={handleDateInputChange}
          className="peer floating-input-custom date-input"
          max={maxDate}
          min={minDate}
          placeholder=""
        />
      )}

      {type !== 'birthDay' && (
        <input
          name={name}
          placeholder={placeholder}
          id={id}
          value={value}
          onChange={onChange as React.ChangeEventHandler<HTMLInputElement>}
          className="peer floating-input-custom"
        />
      )}

      <label htmlFor={id} className="floating-label-custom">
        {placeholder}
      </label>
      {error && (
        <div className="flex items-center mt-1 text-red-500 text-xs">
           <FontAwesomeIcon icon={faCircleExclamation} className="h-4 w-4 mr-1" />
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default LabeledInput;
