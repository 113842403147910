import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface CountdownContextType {
  countdownTime: number;
  isCountdownActive: boolean;
  startCountdown: (initialTime: number) => void;
  resetCountdown: () => void;
}

const CountdownContext = createContext<CountdownContextType | undefined>(undefined);

export const CountdownProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [countdownTime, setCountdownTime] = useState<number>(() => {
    const savedTime = localStorage.getItem('countdownTime');
    return savedTime ? parseInt(savedTime, 10) : 0;
  });
  const [isCountdownActive, setIsCountdownActive] = useState<boolean>(() => {
    const savedActive = localStorage.getItem('isCountdownActive');
    return savedActive ? JSON.parse(savedActive) : false;
  });

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined;

    if (isCountdownActive && countdownTime > 0) {
      interval = setInterval(() => {
        setCountdownTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (countdownTime === 0) {
      setIsCountdownActive(false);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isCountdownActive, countdownTime]);

  useEffect(() => {
    try {
      localStorage.setItem('countdownTime', countdownTime.toString());
      localStorage.setItem('isCountdownActive', JSON.stringify(isCountdownActive));
    } catch (error) {
      console.error('Failed to save countdown state to localStorage', error);
    }
  }, [countdownTime, isCountdownActive]);

  const startCountdown = (initialTime: number) => {
    setCountdownTime(initialTime);
    setIsCountdownActive(true);
  };

  const resetCountdown = () => {
    setIsCountdownActive(false);
    setCountdownTime(0);
  };

  return (
    <CountdownContext.Provider value={{ countdownTime, isCountdownActive, startCountdown, resetCountdown }}>
      {children}
    </CountdownContext.Provider>
  );
};

export const useCountdown = () => {
  const context = useContext(CountdownContext);
  if (context === undefined) {
    throw new Error('useCountdown must be used within a CountdownProvider');
  }
  return context;
};