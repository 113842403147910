import React from 'react';
import LabeledInput from '../../../../common/LabeledInput';
import { useTranslation } from 'react-i18next';
import Phone from '../../../Settings/Phone'; // Phone bileşenini doğru yoldan içe aktarın

interface contactProps {
  email: string;
  city: string;
  district: string;
  postalCode: string;
  address: string;
  phone_number: string;
  setPhone: (v: string) => void;
  onChangeWeb: (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const WorkContact: React.FC<contactProps> = ({
  email,
  setPhone,
  city,
  district,
  postalCode,
  address,
  phone_number,
  onChangeWeb
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row gap-4">
      <div className="flex flex-col gap-4 w-1/2">
        <div>
          <LabeledInput
            name="email"
            placeholder={t('E-mail')}
            id="email"
            width="100%"
            value={email}
            onChange={onChangeWeb}
          />
        </div>
        <div>
          <Phone setPhone={setPhone} phone_number={phone_number} />
        </div>
        <div className="flex flex-row gap-4">
          <textarea
            name="address"
            id="address_line1"
            value={address}
            className="bg-slate-100 shadow-slate-300 shadow-md dark:shadow-black dark:bg-stone-700 text-stone-600 dark:text-zinc-300 rounded-md text-[16px] min-h-[120px] resize-none py-4 px-6 placeholder:text-stone-800 dark:placeholder:text-zinc-300 w-full"
            onChange={onChangeWeb}
            placeholder={t('Type your address here...')}
          ></textarea>
        </div>
      </div>
      <div className="w-1/2">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-4">
            <LabeledInput
              name="city"
              placeholder={t('City')}
              id="city"
              width="calc(50% - 8px)"
              value={city}
              onChange={onChangeWeb}
            />
            <LabeledInput
              name="district"
              placeholder={t('District')}
              id="district"
              width="calc(50% - 8px)"
              value={district}
              onChange={onChangeWeb}
            />
          </div>
          <LabeledInput
            name="postal_code"
            placeholder={t('Postal Code')}
            id="postal_code"
            value={postalCode}
            onChange={onChangeWeb}
          />
          <p className="text-stone-600 dark:text-zinc-300 text-justify italic [16px] m-2">
            {t('Check And Edit Address')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WorkContact;