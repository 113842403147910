import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import clsx from 'clsx';
import { GoBackButtonProps } from '../../types/goBackButton';

const GoBackButton: React.FC<GoBackButtonProps> = ({
  to,
  children,
  className,
  classNameForIcon = "w-5 h-5 md:w-6 md:h-6 mr-1",
  disabled = false,
  ...props
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (to) {
      navigate(to);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="flex justify-center">
      <button
        onClick={handleClick}
        className={clsx(
          "text-center text-base md:text-lg flex items-center dark:hover:text-zinc-50 select-none",
          className,
          { 'opacity-50 cursor-not-allowed': disabled }
        )}
        disabled={disabled}
        {...props}
      >
        <ChevronLeft className={clsx("", classNameForIcon)} />
        {children}
      </button>
    </div>
  );
};

export default GoBackButton;