import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faSquare,
  faSquareCheck,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { WorkShiftData, WorkShiftProps } from '../../../../../types/siteSettings';
import api from '../../../../../services/api';
import Button from '../../../../common/Button';
import { useTranslation } from 'react-i18next';

const daysData = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const WorkShift: React.FC<WorkShiftProps> = ({ onWorkShiftsChange, siteSettingsId }) => {
  const { t } = useTranslation();
  const [dayOpen, setDayOpen] = useState(false);
  const [workShifts, setWorkShifts] = useState<WorkShiftData[]>([]);
  const [commonOpenHour, setCommonOpenHour] = useState('00:00');
  const [commonCloseHour, setCommonCloseHour] = useState('00:00');
  const dayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchWorkShifts = async () => {
      try {
        const response = await api.get(`/admin/worktimes/${siteSettingsId}`);
        if (response.data.data) {
          const shifts = response.data.data.map((shift: any) => ({
            id: shift.id,
            day: shift.day,
            openHour: shift.open_time,
            closeHour: shift.close_time
          }));
          setWorkShifts(shifts);
        }
      } catch (error) {
        console.error('Failed to fetch work shifts:', error);
      }
    };

    if (siteSettingsId) {
      fetchWorkShifts();
    }
  }, [siteSettingsId]);

  useEffect(() => {
    onWorkShiftsChange(workShifts);
  }, [workShifts, onWorkShiftsChange]);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (dayRef.current && !dayRef.current.contains(e.target as Node)) {
        setDayOpen(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dayOpen]);

  const handleCheckboxClick = (day: string) => {
    const index = workShifts.findIndex(shift => shift.day === day);
    if (index === -1) {
      setWorkShifts([...workShifts, { day, openHour: '00:00', closeHour: '00:00' }]);
    } else {
      setWorkShifts(workShifts.filter(shift => shift.day !== day));
    }
  };

  const handleTimeChange = (day: string, field: 'openHour' | 'closeHour', value: string) => {
    setWorkShifts(
      workShifts.map(shift => (shift.day === day ? { ...shift, [field]: value } : shift))
    );
  };

  const handleRemoveShift = (day: string) => {
    setWorkShifts(workShifts.filter(shift => shift.day !== day));
  };

  const applyCommonHours = () => {
    setWorkShifts(
      workShifts.map(shift => ({
        ...shift,
        openHour: commonOpenHour,
        closeHour: commonCloseHour
      }))
    );
  };

  return (
    <div className="w-full h-full flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <div className="w-3/5 relative flex items-center">
          <div ref={dayRef} className="relative w-full">
            <div
              className="bg-slate-100 dark:bg-stone-700 border border-slate-300 dark:border-stone-600 p-3 rounded-md cursor-pointer flex flex-row justify-start items-center shadow-sm shadow-slate-400 dark:shadow-stone-700"
              onClick={() => setDayOpen(!dayOpen)}
            >
              <div className="w-full ml-2">
                <div
                  className={clsx(
                    workShifts.length > 0 && 'text-[14px] text-stone-800 dark:text-zinc-300'
                  )}
                >
                  {t('WorkShift.OpenDays')}
                </div>
                <div className="">
                  {workShifts.map(shift => t(`WorkShift.${shift.day}`)).join(', ')}
                </div>
              </div>
              <div className="flex ml-auto mr-2 text-stone-800 dark:text-zinc-300">
                <FontAwesomeIcon
                  className="w-2 md:w-5 ml-1"
                  icon={dayOpen ? faChevronUp : faChevronDown}
                />
              </div>
            </div>
            {dayOpen && (
              <div className="absolute z-10 w-full text-stone-800 dark:text-zinc-300 cursor-pointer mt-2">
                <ul className="bg-slate-100 dark:bg-stone-700 max-h-[200px] overflow-y-auto scrollbar-thin scrollbar-thumb-slate-400 dark:scrollbar-thumb-stone-500 scrollbar-track-slate-200 dark:scrollbar-track-stone-600 rounded-md shadow-md">
                  {daysData.map((day, index) => (
                    <li
                      key={index}
                      className="flex flex-row gap-2 justify-start items-center cursor-pointer p-2 hover:bg-slate-200 dark:hover:bg-stone-600"
                      onClick={() => handleCheckboxClick(day)}
                    >
                      <input
                        id={day}
                        type="checkbox"
                        className="cursor-pointer hidden"
                        checked={workShifts.some(shift => shift.day === day)}
                        onChange={() => {}}
                      />
                      <span>
                        <FontAwesomeIcon
                          icon={
                            workShifts.some(shift => shift.day === day) ? faSquareCheck : faSquare
                          }
                          className="text-zinc-300"
                        />
                      </span>
                      <label htmlFor={day} className="cursor-pointer">
                        {t(`WorkShift.${day}`)}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        {workShifts && workShifts.length > 1 && (
          <div className="w-1/3 flex flex-col justify-center items-start gap-1">
            <div className="text-sm text-stone-600 dark:text-zinc-300">
              {t('WorkShift.SetCommonHours')}
            </div>
            <div className="flex gap-2">
              <input
                type="time"
                value={commonOpenHour}
                onChange={e => setCommonOpenHour(e.target.value)}
                className="w-1/3 p-1 bg-slate-100 dark:bg-stone-700 rounded-md time-input"
              />
              <input
                type="time"
                value={commonCloseHour}
                onChange={e => setCommonCloseHour(e.target.value)}
                className="w-1/3 p-1 bg-slate-100 dark:bg-stone-700 rounded-md time-input"
              />
              <Button variant="primary" onClick={applyCommonHours}>
                {t('WorkShift.Apply')}
              </Button>
            </div>
          </div>
        )}
      </div>
      {workShifts && workShifts.length > 1 && (
        <div className="text-sm text-stone-600 dark:text-zinc-400 italic">{t('WorkShift.Tip')}</div>
      )}

      <div className="w-full grid grid-cols-5 gap-2 mb-4">
        {workShifts.map(shift => (
          <div
            key={shift.id || shift.day}
            className="relative bg-slate-200 dark:bg-stone-700 border border-slate-300 dark:border-stone-600 p-2 rounded-md shadow-md shadow-slate-400 dark:shadow-stone-700"
          >
            <button
              className="absolute top-2 right-2 text-stone-800 dark:text-zinc-300"
              onClick={() => handleRemoveShift(shift.day)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h3 className="mb-1 font-semibold text-stone-800 dark:text-zinc-300">
              {t(`WorkShift.${shift.day}`)}
            </h3>
            <div className="flex gap-4">
              <div className="text-stone-800 dark:text-zinc-300">
                <label htmlFor={`openHour-${shift.day}`} className="block mb-1 text-xs">
                  {t('WorkShift.OpenHour')}
                </label>
                <input
                  type="time"
                  id={`openHour-${shift.day}`}
                  value={shift.openHour}
                  onChange={e => handleTimeChange(shift.day, 'openHour', e.target.value)}
                  className="w-full p-1 bg-slate-100 dark:bg-stone-800 rounded-md time-input"
                />
              </div>
              <div className="text-stone-800 dark:text-zinc-300">
                <label htmlFor={`closeHour-${shift.day}`} className="block mb-1 text-xs">
                  {t('WorkShift.CloseHour')}
                </label>
                <input
                  type="time"
                  id={`closeHour-${shift.day}`}
                  value={shift.closeHour}
                  onChange={e => handleTimeChange(shift.day, 'closeHour', e.target.value)}
                  className="w-full p-1 bg-slate-100 dark:bg-stone-800 rounded-md time-input"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkShift;
