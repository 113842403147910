import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPenToSquare, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Address } from '../../../../types/address';
import Spinner from '../../../common/Spinner';

interface AddressListProps {
  addresses: Address[];
  loading: boolean;
  selectedAddress: number | null;
  setSelectedAddress: (id: number | null) => void;
  handleEditAddress: (id: number) => void;
  handleAddAddress: () => void;
  handleRemoveAddress: (id: number) => void;
  isSubmitting?: boolean;
}

const AddressList: React.FC<AddressListProps> = ({
  addresses,
  loading,
  selectedAddress,
  setSelectedAddress,
  handleEditAddress,
  handleAddAddress,
  handleRemoveAddress
}) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className="p-6">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
      {addresses.map(address => (
        <div
          key={address.id}
          className={`border rounded-xl p-4 hover:shadow-sm transition-all duration-300 cursor-pointer ${
            selectedAddress === address.id
              ? 'border-stone-400 dark:border-orange-500 bg-slate-400 dark:bg-amber-950'
              : 'border-stone-400 dark:border-stone-600 hover:border-stone-600 dark:hover:border-orange-500 bg-gradient-to-r from-slate-300 to-slate-200 dark:from-stone-800 dark:to-stone-700'
          }`}
          onClick={() => setSelectedAddress(address.id)}
        >
          <div className="flex justify-end items-end mb-1">
            <div className="flex items-center">
              {selectedAddress === address.id && (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="w-5 h-5 text-stone-900 dark:text-orange-500"
                />
              )}
              <button
                className="flex justify-start items-end text-stone-900 dark:text-orange-500 hover:text-orange-400 transition-colors"
                onClick={e => {
                  e.stopPropagation();
                  handleRemoveAddress(address.id);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="w-5 h-5 hover:text-stone-600 dark:hover:text-orange-300 ml-2"
                />
              </button>
              <button
                className="flex justify-start items-end text-stone-900 dark:text-orange-500 hover:text-orange-400 transition-colors"
                onClick={e => {
                  e.stopPropagation();
                  handleEditAddress(address.id);
                }}
              >
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  className="w-5 h-5 hover:text-stone-600 dark:hover:text-orange-300 ml-2"
                />
              </button>
            </div>
          </div>
          <div className="flex flex-row lg:flex-col xl:flex-row justify-between items-center mb-4">
            <h2 className="text-lg font-semibold text-stone-900 dark:text-orange-400 uppercase">
              {address.title || address.type}
            </h2>
          </div>
          <p className="text-sm text-stone-600 dark:text-zinc-300">{address.address_line1}</p>
          <p className="text-sm text-stone-600 dark:text-zinc-300">
            {address.city}
            {address.district && `, ${address.district}`}
            {address.postal_code && ` ${address.postal_code}`}
          </p>
        </div>
      ))}
      <button
        onClick={handleAddAddress}
        className="bg-gradient-to-r from-slate-300 to-slate-200 dark:from-stone-800 dark:to-stone-700 border border-stone-600 dark:border-orange-500 rounded-xl p-8 flex items-center justify-center cursor-pointer hover:shadow-sm hover:shadow-stone-400 dark:hover:shadow-orange-500"
      >
        <FontAwesomeIcon
          icon={faPlus}
          className="text-stone-900 dark:text-orange-500 w-6 h-6 mr-2"
        />
        <span className="text-lg font-semibold text-stone-900 dark:text-orange-400">
          {t('Add New Address')}
        </span>
      </button>
    </div>
  );
};

export default AddressList;
